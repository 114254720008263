import * as React from 'react';

import type { JurisdictionHotlineFooterOverride } from '@dnc/baseline/contentful/types';
import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import IwvHeader from './IwvHeader';

/**
 * Overall structure for an IWV page.
 *
 * By default, renders a 2-column (1-column on mobile) page, with the
 * {@link IwvHeader} on the left side. The `children` are rendered in the right
 * column.
 *
 * Does not render the footer, since that should have been rendered by
 * {@link PageLayout}.
 */
export default function IwvLayout({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
  children,
}: {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  children?: React.ReactNode;
}): React.ReactNode {
  return (
    <div className="column-wrapper">
      <div className="left-column two-col">
        <div className="content-wrapper">
          <IwvHeader
            jurisdiction={jurisdiction}
            customVoterHotline={customVoterHotline}
            hotlineFooterOverride={hotlineFooterOverride}
          />
        </div>
      </div>

      <div id="main-content" className="right-column two-col">
        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  );
}
