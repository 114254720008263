import * as React from 'react';

import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import { ActiveLanguageToLocale } from '@dnc/baseline/utils/localization';
import { useLocale } from '@dnc/shared-components/LocaleContext';

import EnLogo from '../../assets/i-will-vote-logo-we.svg?react';
import EsLogo from '../../assets/voy-a-votar-logo.svg?react';
import { UrlHelper } from '../../services/url-helper';

/**
 * Renders the IWV logo, with a link back to the homepage.
 *
 * If the current locale is Spanish, renders the Voy A Votar logo.
 *
 * If `jurisdiction` is provided, it maintains that jurisdiction by setting the
 * `?state` parameter.
 *
 */
export default function SiteLogo({
  jurisdiction,
}: {
  jurisdiction?: Jurisdiction | undefined;
}): React.ReactNode {
  const location = useLocation();
  const locale = useLocale();
  const intl = useIntl();

  const Logo = locale === ActiveLanguageToLocale['Spanish'] ? EsLogo : EnLogo;

  const urlHelper = UrlHelper.fromLocation(location);

  const logoAriaLabel = intl.formatMessage({
    defaultMessage: 'I Will Vote homepage',
    id: 'HHpunC',
  });

  return (
    <Link aria-label={logoAriaLabel} to={urlHelper.homeURL(jurisdiction)}>
      <Logo aria-hidden="true" className="logo header__logo" />
    </Link>
  );
}
