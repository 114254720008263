import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

/**
 * Fields type definition for content type 'TypeElectionDate'
 * @name TypeElectionDateFields
 * @type {TypeElectionDateFields}
 * @memberof TypeElectionDate
 */
export interface TypeElectionDateFields {
    /**
     * Field type definition for field 'dateTitle' (Title)
     * @name Title
     * @localized false
     */
    dateTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'dateType' (dateType)
     * @name dateType
     * @localized false
     */
    dateType: EntryFieldTypes.Symbol<"Dropoff Ballot By" | "Early Vote Start By" | "Election Day Statewide Hours" | "Election Day" | "In Person Absentee Start By" | "Postmark Ballot By" | "Receive Ballot By" | "Receive Register By" | "Register In Person By" | "Register Online By" | "Register Postmark By" | "Register Same Day" | "Request Ballot By">;
    /**
     * Field type definition for field 'electionDate' (Important Date)
     * @name Important Date
     * @localized false
     */
    electionDate?: EntryFieldTypes.Date;
    /**
     * Field type definition for field 'note' (Note)
     * @name Note
     * @localized true
     */
    note?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'longNote' (Long Note)
     * @name Long Note
     * @localized true
     * @summary For use when “Note” field is too short. Will not display unless “Note” is blank.
     */
    longNote?: EntryFieldTypes.RichText;
}

/**
 * Entry skeleton type definition for content type 'electionDate' (Important Date/Deadline)
 * @name TypeElectionDateSkeleton
 * @type {TypeElectionDateSkeleton}
 * @author 5t1I9ZWa3ziB62Ky03YmRD
 * @since 2021-11-16T20:38:16.251Z
 * @version 41
 */
export type TypeElectionDateSkeleton = EntrySkeletonType<TypeElectionDateFields, "electionDate">;
/**
 * Entry type definition for content type 'electionDate' (Important Date/Deadline)
 * @name TypeElectionDate
 * @type {TypeElectionDate}
 * @author 5t1I9ZWa3ziB62Ky03YmRD
 * @since 2021-11-16T20:38:16.251Z
 * @version 41
 */
export type TypeElectionDate<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeElectionDateSkeleton, Modifiers, Locales>;

export function isTypeElectionDate<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeElectionDate<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'electionDate'
}

export type TypeElectionDateWithoutLinkResolutionResponse = TypeElectionDate<"WITHOUT_LINK_RESOLUTION">;
export type TypeElectionDateWithoutUnresolvableLinksResponse = TypeElectionDate<"WITHOUT_UNRESOLVABLE_LINKS">;
export type TypeElectionDateWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeElectionDate<"WITH_ALL_LOCALES", Locales>;
export type TypeElectionDateWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> = TypeElectionDate<"WITHOUT_LINK_RESOLUTION" | "WITH_ALL_LOCALES", Locales>;
export type TypeElectionDateWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> = TypeElectionDate<"WITHOUT_UNRESOLVABLE_LINKS" | "WITH_ALL_LOCALES", Locales>;
