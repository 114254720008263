import type { State } from '@dnc/baseline/data/jurisdictions';
import type { ActiveLocalizedString } from '@dnc/baseline/utils/localization';

export type ExternalURLs = {
  externalVotingLocationLookupURL: ActiveLocalizedString;
  registrationCheckURL: ActiveLocalizedString | undefined;
};

export const jurisdictionURLs: { [key in State]: ExternalURLs } = {
  AK: {
    registrationCheckURL: {
      en: 'https://myvoterportal.alaska.gov/',
      es: 'https://myvoterportal.alaska.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.elections.alaska.gov/avo/',
      es: 'https://www.elections.alaska.gov/avo/',
    },
  },
  AL: {
    registrationCheckURL: {
      en: 'https://myinfo.alabamavotes.gov/voterview',
      es: 'https://myinfo.alabamavotes.gov/voterview',
    },
    externalVotingLocationLookupURL: {
      en: 'https://myinfo.alabamavotes.gov/voterview',
      es: 'https://myinfo.alabamavotes.gov/voterview',
    },
  },
  AR: {
    registrationCheckURL: {
      en: 'https://www.voterview.ar-nova.org/voterview',
      es: 'https://www.voterview.ar-nova.org/voterview',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.voterview.ar-nova.org/voterview',
      es: 'https://www.voterview.ar-nova.org/voterview',
    },
  },
  AZ: {
    registrationCheckURL: {
      en: 'https://my.arizona.vote/WhereToVote.aspx?s=individual',
      es: 'https://my.arizona.vote/WhereToVote.aspx?s=individual',
    },
    externalVotingLocationLookupURL: {
      en: 'https://my.arizona.vote/WhereToVote.aspx?s=address',
      es: 'https://my.arizona.vote/WhereToVote.aspx?s=address',
    },
  },
  CA: {
    registrationCheckURL: {
      en: 'https://voterstatus.sos.ca.gov/',
      es: 'https://voterstatus.sos.ca.gov/ES',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.sos.ca.gov/elections/polling-place',
      es: 'https://www.sos.ca.gov/elections/polling-place',
    },
  },
  CO: {
    registrationCheckURL: {
      en: 'https://www.sos.state.co.us/voter/pages/pub/olvr/findVoterReg.xhtml',
      es: 'https://www.sos.state.co.us/voter/pages/pub/olvr/findVoterReg.xhtml',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.coloradosos.gov/pubs/elections/VIP.html',
      es: 'https://www.coloradosos.gov/pubs/elections/VIPesp.html',
    },
  },
  CT: {
    registrationCheckURL: {
      en: 'http://www.dir.ct.gov/sots/LookUp.aspx',
      es: 'http://www.dir.ct.gov/sots/LookUp.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://portaldir.ct.gov/sots/LookUp.aspx',
      es: 'https://portaldir.ct.gov/sots/LookUp.aspx',
    },
  },
  DC: {
    registrationCheckURL: {
      en: 'https://apps.dcboe.org/vrs',
      es: 'https://apps.dcboe.org/vrs',
    },
    externalVotingLocationLookupURL: {
      en: 'https://dcboe.org/voters/find-out-where-to-vote/vote-center-locator-tool',
      es: 'https://dcboe.org/voters/find-out-where-to-vote/vote-center-locator-tool',
    },
  },
  DE: {
    registrationCheckURL: {
      en: 'https://ivote.de.gov/voterview',
      es: 'https://ivote.de.gov/voterview',
    },
    externalVotingLocationLookupURL: {
      en: 'https://ivote.de.gov/VoterView',
      es: 'https://ivote.de.gov/VoterView',
    },
  },
  FL: {
    registrationCheckURL: {
      en: 'http://registration.elections.myflorida.com/CheckVoterStatus',
      es: 'http://registration.elections.myflorida.com/CheckVoterStatus',
    },
    externalVotingLocationLookupURL: {
      en: 'https://registration.elections.myflorida.com/CheckVoterStatus',
      es: 'https://registration.elections.myflorida.com/CheckVoterStatus',
    },
  },
  GA: {
    registrationCheckURL: {
      en: 'https://www.mvp.sos.ga.gov/MVP/mvp.do',
      es: 'https://www.mvp.sos.ga.gov/MVP/mvp.do',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.mvp.sos.ga.gov/MVP/mvp.do',
      es: 'https://www.mvp.sos.ga.gov/MVP/mvp.do',
    },
  },
  HI: {
    registrationCheckURL: {
      en: 'https://olvr.hawaii.gov/',
      es: 'https://olvr.hawaii.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://elections.hawaii.gov/voter-service-centers-and-places-of-deposit/',
      es: 'https://elections.hawaii.gov/voter-service-centers-and-places-of-deposit/',
    },
  },
  IA: {
    registrationCheckURL: {
      en: 'https://sos.iowa.gov/elections/voterreg/regtovote/search.aspx',
      es: 'https://sos.iowa.gov/elections/voterreg/regtovote/search.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://sos.iowa.gov/elections/voterreg/pollingplace/search.aspx',
      es: 'https://sos.iowa.gov/elections/voterreg/pollingplace/search.aspx',
    },
  },
  ID: {
    registrationCheckURL: {
      en: 'https://elections.sos.idaho.gov/ElectionLink/ElectionLink/VoterSearch.aspx',
      es: 'https://elections.sos.idaho.gov/ElectionLink/ElectionLink/VoterSearch.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://elections.sos.idaho.gov/ElectionLink/ElectionLink/ViewPollingLocation.aspx',
      es: 'https://elections.sos.idaho.gov/ElectionLink/ElectionLink/ViewPollingLocation.aspx',
    },
  },
  IL: {
    registrationCheckURL: {
      en: 'https://ova.elections.il.gov/RegistrationLookup.aspx',
      es: 'https://ova.elections.il.gov/RegistrationLookup.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://ova.elections.il.gov/PollingPlaceLookup.aspx',
      es: 'https://ova.elections.il.gov/PollingPlaceLookup.aspx',
    },
  },
  IN: {
    registrationCheckURL: {
      en: 'https://indianavoters.in.gov/',
      es: 'https://indianavoters.in.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://indianavoters.in.gov/',
      es: 'https://indianavoters.in.gov/',
    },
  },
  KS: {
    registrationCheckURL: {
      en: 'https://myvoteinfo.voteks.org/VoterView',
      es: 'https://myvoteinfo.voteks.org/VoterView',
    },
    externalVotingLocationLookupURL: {
      en: 'https://myvoteinfo.voteks.org/VoterView',
      es: 'https://myvoteinfo.voteks.org/VoterView',
    },
  },
  KY: {
    registrationCheckURL: {
      en: 'https://vrsws.sos.ky.gov/vic/',
      es: 'https://vrsws.sos.ky.gov/vic/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://elect.ky.gov/Voters/Pages/Polling-Locations.aspx',
      es: 'https://elect.ky.gov/Voters/Pages/Polling-Locations.aspx',
    },
  },
  LA: {
    registrationCheckURL: {
      en: 'https://voterportal.sos.la.gov/Home/VoterLogin',
      es: 'https://voterportal.sos.la.gov/Home/VoterLogin',
    },
    externalVotingLocationLookupURL: {
      en: 'https://voterportal.sos.la.gov/Home/VoterLogin',
      es: 'https://voterportal.sos.la.gov/Home/VoterLogin',
    },
  },
  MA: {
    registrationCheckURL: {
      en: 'https://www.sec.state.ma.us/VoterRegistrationSearch/MyVoterRegStatus.aspx',
      es: 'https://www.sec.state.ma.us/VoterRegistrationSearch/MyVoterRegStatus.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.sec.state.ma.us/WhereDoIVoteMA/bal/MyElectionInfo.aspx',
      es: 'https://www.sec.state.ma.us/WhereDoIVoteMA/bal/MyElectionInfo.aspx',
    },
  },
  MD: {
    registrationCheckURL: {
      en: 'https://voterservices.elections.maryland.gov/VoterSearch',
      es: 'https://voterservices.elections.maryland.gov/VoterSearch',
    },
    externalVotingLocationLookupURL: {
      en: 'https://voterservices.elections.maryland.gov/PollingPlaceSearch',
      es: 'https://voterservices.elections.maryland.gov/PollingPlaceSearch',
    },
  },
  ME: {
    registrationCheckURL: undefined,
    externalVotingLocationLookupURL: {
      en: 'https://www.maine.gov/portal/government/edemocracy/voter_lookup.php',
      es: 'https://www.maine.gov/portal/government/edemocracy/voter_lookup.php',
    },
  },
  MI: {
    registrationCheckURL: {
      en: 'https://mvic.sos.state.mi.us/Voter/Index',
      es: 'https://mvic.sos.state.mi.us/Voter/Index',
    },
    externalVotingLocationLookupURL: {
      en: 'https://mvic.sos.state.mi.us/Voter/Index',
      es: 'https://mvic.sos.state.mi.us/Voter/Index',
    },
  },
  MN: {
    registrationCheckURL: {
      en: 'https://mnvotes.sos.state.mn.us/VoterStatus.aspx',
      es: 'https://mnvotes.sos.state.mn.us/VoterStatus.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://pollfinder.sos.state.mn.us/',
      es: 'https://pollfinder.sos.state.mn.us/',
    },
  },
  MO: {
    registrationCheckURL: {
      en: 'http://s1.sos.mo.gov/elections/voterlookup/',
      es: 'http://s1.sos.mo.gov/elections/voterlookup/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://voteroutreach.sos.mo.gov/portal',
      es: 'https://voteroutreach.sos.mo.gov/portal',
    },
  },
  MS: {
    registrationCheckURL: {
      en: 'https://www.msegov.com/sos/voter_registration/amiregistered/Search',
      es: 'https://www.msegov.com/sos/voter_registration/amiregistered/Search',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.sos.ms.gov/elections-voting/polling-place-locator',
      es: 'https://www.sos.ms.gov/elections-voting/polling-place-locator',
    },
  },
  MT: {
    registrationCheckURL: {
      en: 'https://app.mt.gov/voterinfo/',
      es: 'https://app.mt.gov/voterinfo/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://app.mt.gov/voterinfo/',
      es: 'https://app.mt.gov/voterinfo/',
    },
  },
  NC: {
    registrationCheckURL: {
      en: 'https://vt.ncsbe.gov/RegLkup/',
      es: 'https://vt.ncsbe.gov/RegLkup/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://vt.ncsbe.gov/PPLkup/',
      es: 'https://vt.ncsbe.gov/PPLkup/',
    },
  },
  ND: {
    registrationCheckURL: {
      en: 'https://vip.sos.nd.gov/WhereToVoteID.aspx?tab=VoterLookup&ptlPKID=7&ptlhPKID=51',
      es: 'https://vip.sos.nd.gov/WhereToVoteID.aspx?tab=VoterLookup&ptlPKID=7&ptlhPKID=51',
    },
    externalVotingLocationLookupURL: {
      en: 'https://vip.sos.nd.gov/wheretovote.aspx',
      es: 'https://vip.sos.nd.gov/wheretovote.aspx',
    },
  },
  NE: {
    registrationCheckURL: {
      en: 'https://www.votercheck.necvr.ne.gov/',
      es: 'https://www.votercheck.necvr.ne.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.votercheck.necvr.ne.gov/voterview',
      es: 'https://www.votercheck.necvr.ne.gov/voterview',
    },
  },
  NH: {
    registrationCheckURL: {
      en: 'https://app.sos.nh.gov/voterinformation',
      es: 'https://app.sos.nh.gov/voterinformation',
    },
    externalVotingLocationLookupURL: {
      en: 'https://app.sos.nh.gov/pollingplacesampleballot',
      es: 'https://app.sos.nh.gov/pollingplacesampleballot',
    },
  },
  NJ: {
    registrationCheckURL: {
      en: 'https://voter.svrs.nj.gov/registration-check',
      es: 'https://voter.svrs.nj.gov/registration-check',
    },
    externalVotingLocationLookupURL: {
      en: 'https://nj.gov/state/elections/vote-polling-location.shtml',
      es: 'https://nj.gov/state/elections/vote-polling-location.shtml',
    },
  },
  NM: {
    registrationCheckURL: {
      en: 'https://voterportal.servis.sos.state.nm.us/WhereToVote.aspx',
      es: 'https://voterportal.servis.sos.state.nm.us/WhereToVote.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://voterportal.servis.sos.state.nm.us/WhereToVote.aspx',
      es: 'https://voterportal.servis.sos.state.nm.us/WhereToVote.aspx',
    },
  },
  NV: {
    registrationCheckURL: {
      en: 'https://www.nvsos.gov/VoterSearch/',
      es: 'https://www.nvsos.gov/VoterSearch/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.nvsos.gov/sos/elections/election-information/2024-election-information/2024-voting-locations',
      es: 'https://www.nvsos.gov/sos/elections/election-information/2024-election-information/2024-voting-locations',
    },
  },
  NY: {
    registrationCheckURL: {
      en: 'https://voterlookup.elections.ny.gov/',
      es: 'https://voterlookup.elections.ny.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://voterlookup.elections.ny.gov/',
      es: 'https://voterlookup.elections.ny.gov/',
    },
  },
  OH: {
    registrationCheckURL: {
      en: 'https://voterlookup.ohiosos.gov/voterlookup.aspx',
      es: 'https://voterlookup.ohiosos.gov/voterlookup.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.ohiosos.gov/elections/voters/toolkit/polling-location/',
      es: 'https://www.ohiosos.gov/elections/voters/toolkit/polling-location/',
    },
  },
  OK: {
    registrationCheckURL: {
      en: 'https://okvoterportal.okelections.us/',
      es: 'https://okvoterportal.okelections.us/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.ok.gov/elections/',
      es: 'https://www.ok.gov/elections/',
    },
  },
  OR: {
    registrationCheckURL: {
      en: 'https://secure.sos.state.or.us/orestar/vr/showVoterSearch.do?lang=eng&source=SOS',
      es: 'https://secure.sos.state.or.us/orestar/vr/showVoterSearch.do?lang=eng&source=SOS',
    },
    externalVotingLocationLookupURL: {
      en: 'https://sos.oregon.gov/voting/Pages/drop-box-locator.aspx',
      es: 'https://sos.oregon.gov/voting/Pages/drop-box-locator.aspx',
    },
  },
  PA: {
    registrationCheckURL: {
      en: 'https://www.pavoterservices.pa.gov/Pages/voterregistrationstatus.aspx',
      es: 'https://www.pavoterservices.pa.gov/Pages/voterregistrationstatus.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.pavoterservices.pa.gov/pages/pollingplaceinfo.aspx',
      es: 'https://www.pavoterservices.pa.gov/pages/pollingplaceinfo.aspx',
    },
  },
  RI: {
    registrationCheckURL: {
      en: 'https://vote.sos.ri.gov/Home/UpdateVoterRecord?ActiveFlag=0',
      es: 'https://vote.sos.ri.gov/Home/UpdateVoterRecord?ActiveFlag=0',
    },
    externalVotingLocationLookupURL: {
      en: 'https://vote.sos.ri.gov/Home/PollingPlaces?ActiveFlag=2',
      es: 'https://vote.sos.ri.gov/Home/PollingPlaces?ActiveFlag=2',
    },
  },
  SC: {
    registrationCheckURL: {
      en: 'https://vrems.scvotes.sc.gov/Voter/Login',
      es: 'https://vrems.scvotes.sc.gov/Voter/Login',
    },
    externalVotingLocationLookupURL: {
      en: 'https://info.scvotes.sc.gov/Eng/VoterStatistics/VoterHistory/PrecinctAndPolling.aspx',
      es: 'https://info.scvotes.sc.gov/Eng/VoterStatistics/VoterHistory/PrecinctAndPolling.aspx',
    },
  },
  SD: {
    registrationCheckURL: {
      en: 'https://vip.sdsos.gov/viplogin.aspx',
      es: 'https://vip.sdsos.gov/viplogin.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://vip.sdsos.gov/viplogin.aspx',
      es: 'https://vip.sdsos.gov/viplogin.aspx',
    },
  },
  TN: {
    registrationCheckURL: {
      en: 'https://tnmap.tn.gov/voterlookup/',
      es: 'https://tnmap.tn.gov/voterlookup/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://web.go-vote-tn.elections.tn.gov/',
      es: 'https://web.go-vote-tn.elections.tn.gov/',
    },
  },
  TX: {
    registrationCheckURL: {
      en: 'https://teamrv-mvp.sos.texas.gov/MVP/mvp.do',
      es: 'https://teamrv-mvp.sos.texas.gov/MVP/mvp.do',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.votetexas.gov/voting/where.html#where-to-vote',
      es: 'https://www.votetexas.gov/voting/where.html#where-to-vote',
    },
  },
  UT: {
    registrationCheckURL: {
      en: 'https://votesearch.utah.gov/voter-search/search/search-by-voter/voter-info',
      es: 'https://votesearch.utah.gov/voter-search/search/search-by-voter/voter-info',
    },
    externalVotingLocationLookupURL: {
      en: 'https://votesearch.utah.gov/voter-search/search/search-by-address/how-and-where-can-i-vote',
      es: 'https://votesearch.utah.gov/voter-search/search/search-by-address/how-and-where-can-i-vote',
    },
  },
  VA: {
    registrationCheckURL: {
      en: 'https://vote.elections.virginia.gov/VoterInformation',
      es: 'https://vote.elections.virginia.gov/VoterInformation',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.elections.virginia.gov/citizen-portal/',
      es: 'https://www.elections.virginia.gov/citizen-portal/',
    },
  },
  VT: {
    registrationCheckURL: {
      en: 'https://mvp.vermont.gov/',
      es: 'https://mvp.vermont.gov/',
    },
    externalVotingLocationLookupURL: {
      en: 'https://sos.vermont.gov/elections/voters/polling-places/',
      es: 'https://sos.vermont.gov/elections/voters/polling-places/',
    },
  },
  WA: {
    registrationCheckURL: {
      en: 'https://voter.votewa.gov/WhereToVote.aspx',
      es: 'https://voter.votewa.gov/WhereToVote.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://www.sos.wa.gov/elections/faq_vote_by_mail.aspx',
      es: 'https://www.sos.wa.gov/elections/faq_vote_by_mail.aspx',
    },
  },
  WI: {
    registrationCheckURL: {
      en: 'https://myvote.wi.gov/en-us/RegisterToVote',
      es: 'https://myvote.wi.gov/en-us/RegisterToVote',
    },
    externalVotingLocationLookupURL: {
      en: 'https://myvote.wi.gov/en-US/FindMyPollingPlace',
      es: 'https://myvote.wi.gov/en-US/FindMyPollingPlace',
    },
  },
  WV: {
    registrationCheckURL: {
      // as of 7-27-22 the same URL is used for registration check and finding
      // polling place.
      en: 'https://apps.sos.wv.gov/Elections/Voter/FindMyPollingPlace',
      es: 'https://apps.sos.wv.gov/Elections/Voter/FindMyPollingPlace',
    },
    externalVotingLocationLookupURL: {
      en: 'https://apps.sos.wv.gov/Elections/voter/FindMyPollingPlace',
      es: 'https://apps.sos.wv.gov/Elections/voter/FindMyPollingPlace',
    },
  },
  WY: {
    registrationCheckURL: {
      en: 'https://sos.wyo.gov/Elections/State/RegisteringToVote.aspx',
      es: 'https://sos.wyo.gov/Elections/State/RegisteringToVote.aspx',
    },
    externalVotingLocationLookupURL: {
      en: 'https://sos.wyo.gov/elections/pollplace/default.aspx',
      es: 'https://sos.wyo.gov/elections/pollplace/default.aspx',
    },
  },
};
