import type { IntlShape } from 'react-intl';

// Includes DC
export type State =
  | 'AK'
  | 'AL'
  | 'AR'
  | 'AZ'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'MA'
  | 'MD'
  | 'ME'
  | 'MI'
  | 'MN'
  | 'MO'
  | 'MS'
  | 'MT'
  | 'NC'
  | 'ND'
  | 'NE'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NV'
  | 'NY'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VA'
  | 'VT'
  | 'WA'
  | 'WI'
  | 'WV'
  | 'WY';

export type Territory = 'AS' | 'GU' | 'MP' | 'PR' | 'VI';

export type Jurisdiction = State | Territory;

export namespace Jurisdictions {
  export const territoriesStringsEn: { [key in Territory]: string } = {
    AS: 'American Samoa',
    GU: 'Guam',
    MP: 'Northern Mariana Islands',
    PR: 'Puerto Rico',
    VI: 'Virgin Islands',
    // TODO: Get some data for this
    // FM: 'Federated States of Micronesia',
    // MH: 'Marshall Islands',
    // PW: 'Palau',
  };

  export const stateStringsEn: { [key in State]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    // The Bonus State:
    DC: 'District of Columbia',
  };

  export const allStringsEn: { [key in Jurisdiction]: string } = {
    ...stateStringsEn,
    ...territoriesStringsEn,
  };

  export const stateCodesEn: State[] = (
    Object.keys(Jurisdictions.stateStringsEn) as State[]
  ).sort();

  export function isJurisdiction(
    jurisdiction: unknown
  ): jurisdiction is Jurisdiction {
    return !!(
      typeof jurisdiction === 'string' &&
      allStringsEn[jurisdiction as Jurisdiction]
    );
  }

  export function isState(
    jurisdiction: string | undefined
  ): jurisdiction is State {
    return !!(jurisdiction && stateStringsEn[jurisdiction as State]);
  }

  export function isTerritory(
    jurisdiction: string | undefined
  ): jurisdiction is Territory {
    return !!(jurisdiction && territoriesStringsEn[jurisdiction as Territory]);
  }

  export const localized = (
    jurisdiction: Jurisdiction,
    intl: IntlShape
  ): string => {
    switch (jurisdiction) {
      // TODO: Add missing translations for:
      // MP: 'Northern Mariana Islands',
      // VI: 'Virgin Islands',
      // TODO: Get some data for the following:
      // FM: 'Federated States of Micronesia',
      // MH: 'Marshall Islands',
      // PW: 'Palau',
      case 'DC': {
        return intl.formatMessage({
          defaultMessage: 'District of Columbia',
          id: 'jurisdictions.DC',
        });
      }
      case 'MP': {
        return intl.formatMessage({
          defaultMessage: 'Northern Mariana Islands',
          id: 'jurisdictions.MP',
        });
      }
      case 'VI': {
        return intl.formatMessage({
          defaultMessage: 'Virgin Islands',
          id: 'jurisdictions.VI',
        });
      }
      case 'AL': {
        return intl.formatMessage({
          defaultMessage: 'Alabama',
          id: 'jurisdictions.AL',
        });
      }
      case 'AK': {
        return intl.formatMessage({
          defaultMessage: 'Alaska',
          id: 'jurisdictions.AK',
        });
      }
      case 'AZ': {
        return intl.formatMessage({
          defaultMessage: 'Arizona',
          id: 'jurisdictions.AZ',
        });
      }
      case 'AR': {
        return intl.formatMessage({
          defaultMessage: 'Arkansas',
          id: 'jurisdictions.AR',
        });
      }
      case 'CA': {
        return intl.formatMessage({
          defaultMessage: 'California',
          id: 'jurisdictions.CA',
        });
      }
      case 'CO': {
        return intl.formatMessage({
          defaultMessage: 'Colorado',
          id: 'jurisdictions.CO',
        });
      }
      case 'CT': {
        return intl.formatMessage({
          defaultMessage: 'Connecticut',
          id: 'jurisdictions.CT',
        });
      }
      case 'DE': {
        return intl.formatMessage({
          defaultMessage: 'Delaware',
          id: 'jurisdictions.DE',
        });
      }
      case 'FL': {
        return intl.formatMessage({
          defaultMessage: 'Florida',
          id: 'jurisdictions.FL',
        });
      }
      case 'GA': {
        return intl.formatMessage({
          defaultMessage: 'Georgia',
          id: 'jurisdictions.GA',
        });
      }
      case 'HI': {
        return intl.formatMessage({
          defaultMessage: 'Hawaii',
          id: 'jurisdictions.HI',
        });
      }
      case 'ID': {
        return intl.formatMessage({
          defaultMessage: 'Idaho',
          id: 'jurisdictions.ID',
        });
      }
      case 'IL': {
        return intl.formatMessage({
          defaultMessage: 'Illinois',
          id: 'jurisdictions.IL',
        });
      }
      case 'IN': {
        return intl.formatMessage({
          defaultMessage: 'Indiana',
          id: 'jurisdictions.IN',
        });
      }
      case 'IA': {
        return intl.formatMessage({
          defaultMessage: 'Iowa',
          id: 'jurisdictions.IA',
        });
      }
      case 'KS': {
        return intl.formatMessage({
          defaultMessage: 'Kansas',
          id: 'jurisdictions.KS',
        });
      }
      case 'KY': {
        return intl.formatMessage({
          defaultMessage: 'Kentucky',
          id: 'jurisdictions.KY',
        });
      }
      case 'LA': {
        return intl.formatMessage({
          defaultMessage: 'Louisiana',
          id: 'jurisdictions.LA',
        });
      }
      case 'ME': {
        return intl.formatMessage({
          defaultMessage: 'Maine',
          id: 'jurisdictions.ME',
        });
      }
      case 'MD': {
        return intl.formatMessage({
          defaultMessage: 'Maryland',
          id: 'jurisdictions.MD',
        });
      }
      case 'MA': {
        return intl.formatMessage({
          defaultMessage: 'Massachusetts',
          id: 'jurisdictions.MA',
        });
      }
      case 'MI': {
        return intl.formatMessage({
          defaultMessage: 'Michigan',
          id: 'jurisdictions.MI',
        });
      }
      case 'MN': {
        return intl.formatMessage({
          defaultMessage: 'Minnesota',
          id: 'jurisdictions.MN',
        });
      }
      case 'MS': {
        return intl.formatMessage({
          defaultMessage: 'Mississippi',
          id: 'jurisdictions.MS',
        });
      }
      case 'MO': {
        return intl.formatMessage({
          defaultMessage: 'Missouri',
          id: 'jurisdictions.MO',
        });
      }
      case 'MT': {
        return intl.formatMessage({
          defaultMessage: 'Montana',
          id: 'jurisdictions.MT',
        });
      }
      case 'NE': {
        return intl.formatMessage({
          defaultMessage: 'Nebraska',
          id: 'jurisdictions.NE',
        });
      }
      case 'NV': {
        return intl.formatMessage({
          defaultMessage: 'Nevada',
          id: 'jurisdictions.NV',
        });
      }
      case 'NH': {
        return intl.formatMessage({
          defaultMessage: 'New Hampshire',
          id: 'jurisdictions.NH',
        });
      }
      case 'NJ': {
        return intl.formatMessage({
          defaultMessage: 'New Jersey',
          id: 'jurisdictions.NJ',
        });
      }
      case 'NM': {
        return intl.formatMessage({
          defaultMessage: 'New Mexico',
          id: 'jurisdictions.NM',
        });
      }
      case 'NY': {
        return intl.formatMessage({
          defaultMessage: 'New York',
          id: 'jurisdictions.NY',
        });
      }
      case 'NC': {
        return intl.formatMessage({
          defaultMessage: 'North Carolina',
          id: 'jurisdictions.NC',
        });
      }
      case 'ND': {
        return intl.formatMessage({
          defaultMessage: 'North Dakota',
          id: 'jurisdictions.ND',
        });
      }
      case 'OH': {
        return intl.formatMessage({
          defaultMessage: 'Ohio',
          id: 'jurisdictions.OH',
        });
      }
      case 'OK': {
        return intl.formatMessage({
          defaultMessage: 'Oklahoma',
          id: 'jurisdictions.OK',
        });
      }
      case 'OR': {
        return intl.formatMessage({
          defaultMessage: 'Oregon',
          id: 'jurisdictions.OR',
        });
      }
      case 'PA': {
        return intl.formatMessage({
          defaultMessage: 'Pennsylvania',
          id: 'jurisdictions.PA',
        });
      }
      case 'RI': {
        return intl.formatMessage({
          defaultMessage: 'Rhode Island',
          id: 'jurisdictions.RI',
        });
      }
      case 'SC': {
        return intl.formatMessage({
          defaultMessage: 'South Carolina',
          id: 'jurisdictions.SC',
        });
      }
      case 'SD': {
        return intl.formatMessage({
          defaultMessage: 'South Dakota',
          id: 'jurisdictions.SD',
        });
      }
      case 'TN': {
        return intl.formatMessage({
          defaultMessage: 'Tennessee',
          id: 'jurisdictions.TN',
        });
      }
      case 'TX': {
        return intl.formatMessage({
          defaultMessage: 'Texas',
          id: 'jurisdictions.TX',
        });
      }
      case 'UT': {
        return intl.formatMessage({
          defaultMessage: 'Utah',
          id: 'jurisdictions.UT',
        });
      }
      case 'VT': {
        return intl.formatMessage({
          defaultMessage: 'Vermont',
          id: 'jurisdictions.VT',
        });
      }
      case 'VA': {
        return intl.formatMessage({
          defaultMessage: 'Virginia',
          id: 'jurisdictions.VA',
        });
      }
      case 'WA': {
        return intl.formatMessage({
          defaultMessage: 'Washington',
          id: 'jurisdictions.WA',
        });
      }
      case 'WV': {
        return intl.formatMessage({
          defaultMessage: 'West Virginia',
          id: 'jurisdictions.WV',
        });
      }
      case 'WI': {
        return intl.formatMessage({
          defaultMessage: 'Wisconsin',
          id: 'jurisdictions.WI',
        });
      }
      case 'WY': {
        return intl.formatMessage({
          defaultMessage: 'Wyoming',
          id: 'jurisdictions.WY',
        });
      }
      case 'AS': {
        return intl.formatMessage({
          defaultMessage: 'American Samoa',
          id: 'jurisdictions.AS',
        });
      }
      case 'GU': {
        return intl.formatMessage({
          defaultMessage: 'Guam',
          id: 'jurisdictions.GU',
        });
      }
      case 'PR': {
        return intl.formatMessage({
          defaultMessage: 'Puerto Rico',
          id: 'jurisdictions.PR',
        });
      }
      default: {
        return '';
      }
    }
  };

  /**
   * Returns jurisdiction with prefix "the"
   * for use as subject or direct object in a sentence
   */
  export const localizedWithPrefixThe = (
    jurisdiction: Jurisdiction,
    intl: IntlShape
  ): string => {
    switch (jurisdiction) {
      case 'DC': {
        return intl.formatMessage({
          defaultMessage: 'the District of Columbia',
          id: 'jurisdictions.theDC',
        });
      }
      case 'MP': {
        return intl.formatMessage({
          defaultMessage: 'the Northern Mariana Islands',
          id: 'jurisdictions.theMP',
        });
      }
      case 'VI': {
        return intl.formatMessage({
          defaultMessage: 'the Virgin Islands',
          id: 'jurisdictions.theVI',
        });
      }
      default: {
        return localized(jurisdiction, intl);
      }
    }
  };
}
