import * as React from 'react';
import { useLocation, useMatches } from 'react-router';

import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';
import {
  AnalyticsService,
  GoogleAnalyticsAdapter,
  NullAnalyticsAdapter,
} from '@dnc/baseline/services/analytics-service';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';

const AnalyticsContext = React.createContext<AnalyticsService | null>(null);

/**
 * Returns the globally-provided {@link AnalyticsService} from
 * {@link GaAnalyticsProvider}.
 *
 * Throws an Error if no AnalyticsProvider was set.
 */
export function useAnalytics(): AnalyticsService {
  const analytics = React.useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics called outside of GaAnalyticsProvider');
  }

  return analytics;
}

/**
 * Provider that creates an {@link AnalyticsService} using a
 * {@link GoogleAnalyticsAdapter}, with the latest language, jurisdiction, and
 * routes, and also provides it to child components through a React
 * {@link Context}.
 *
 * @see useAnalytics
 */
export const GaAnalyticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const locale = React.useContext(LocaleContext);

  const jurisdiction = useLoadedJurisdiction();

  // We create a fresh instance in a useMemo so that reporting done by
  // downstream components will have the latest information, rather than only
  // having it after a `useLayoutEffect` or `useEffect` fires.
  const analytics = React.useMemo(() => {
    // Do a null adapter on the server. This only comes up in dev mode and then
    // only for routes that don’t have `clientLoader`s (so RR tries to render
    // the `<Root>` component on the server).
    const adapter = import.meta.env.SSR
      ? new NullAnalyticsAdapter()
      : new GoogleAnalyticsAdapter();
    const analytics = new AnalyticsService(adapter);

    analytics.setLocale(locale);
    analytics.setJurisdiction(jurisdiction);

    return analytics;
  }, [locale, jurisdiction]);

  // TODO(fiona): Consider removing this and relying on GA’s built-in
  // history API tracking.
  React.useEffect(
    () => {
      analytics.routeChanged(location, locale, jurisdiction);
    },
    // We only want to send routeChanged when pathname or search changed, not
    // any other location changes.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsContextProviderForTest = AnalyticsContext.Provider;

/**
 * Hook that returns the page’s current jurisdiction by examining the data
 * loaded by any current routes’s `clientLoader` functions.
 *
 * Allows us to capture the jurisdiction regardless of whether it came from a
 * `?state=` query parameter, a geo-ip load, or a `/:jurisdiction/` path
 * parameter.
 *
 * This is either very clever (to keep the Analytics provider general and
 * high-level) or very foolish (since this is an un-enforced dependency on the
 * shape of data returned by our loader functions.)
 */
function useLoadedJurisdiction(): Jurisdiction | undefined {
  const routeMatches = useMatches();

  // Look through the data returned by all of the `clientLoader` functions for
  // the current route. Returns the first `jurisdiction` value it finds that is
  // a valid `Jurisdiction`.
  for (const match of routeMatches) {
    if (
      typeof match.data === 'object' &&
      // check because `typeof null === 'object'`
      match.data &&
      'jurisdiction' in match.data &&
      Jurisdictions.isJurisdiction(match.data['jurisdiction'])
    ) {
      return match.data['jurisdiction'];
    }
  }

  return undefined;
}
