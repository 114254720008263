import * as React from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';

import type { JurisdictionHotlineFooterOverride } from '@dnc/baseline/contentful/types';
import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { LocalizedRichText } from '@dnc/shared-components/LocalizedRichText';

import SiteLogo from '../SiteLogo';

export const HOTLINE_SMS_BODY_MSG = defineMessage({
  defaultMessage: 'VOTE',
  id: 'mQzbfB',
  description:
    'The body of the SMS sent to 70888 to get a response. Varies by language so we can respond in the right language.',
});

/**
 * “Header” for the 2-column IWV pages. On large screens this is instead the
 * left-hand column.
 */
export default function IwvHeader({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
}: {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
}): React.ReactNode {
  return (
    <div className="flex flex-col gap-2 pb-1 lg:gap-3">
      <SiteLogo jurisdiction={jurisdiction} />

      <div className="text-lilac-800 text-sm my-4 lg:text-base">
        <FormattedMessage
          defaultMessage="Have questions about voting?"
          id="wrapper.haveQuestionsHtml.questionsAboutVoting"
          tagName="strong"
        />

        <br />

        <HotlineContent
          jurisdiction={jurisdiction}
          customVoterHotline={customVoterHotline}
          hotlineFooterOverride={hotlineFooterOverride}
        />

        <br />

        <a href="#sms-disclaimer">
          <FormattedMessage
            defaultMessage="Disclaimer below."
            id="wrapper.haveQuestionsHtml.disclaimer"
          />
        </a>
      </div>
    </div>
  );
}

/**
 * This component chooses which hotline will be displayed in the header. Display
 * options are the custom voter hotline, a hotline override, or neither (the
 * default hotline).
 */
function HotlineContent({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
}: {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
}): React.ReactNode {
  const intl = useIntl();
  const hotlineSmsBodyText = intl.formatMessage(HOTLINE_SMS_BODY_MSG);

  if (jurisdiction && customVoterHotline) {
    return (
      <FormattedMessage
        defaultMessage="Call the {jurisdiction} voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
        values={{
          smsBody: hotlineSmsBodyText,
          'sms-link': (chunks) => (
            <a
              href={`sms://70888&body=${hotlineSmsBodyText}`}
              className="no-wrap"
              key="sms-link"
            >
              {chunks}
            </a>
          ),
          hotline: (
            <a
              href={`tel:${customVoterHotline}`}
              className="no-wrap"
              key="hotline-link"
            >
              {customVoterHotline}
            </a>
          ),
          jurisdiction: Jurisdictions.localized(jurisdiction, intl),
        }}
        id="OhpnmQ"
      />
    );
  } else if (hotlineFooterOverride) {
    return (
      <div className="text-lilac-800 text-sm my-4 lg:text-base">
        <LocalizedRichText
          richTextBlock={hotlineFooterOverride.hotlineOverrideCopy?.copy}
          className="text-sm my-4 lg:text-base"
          innerParagraphStyleOverride="text-lilac-800"
        />
      </div>
    );
  } else {
    return (
      <FormattedMessage
        defaultMessage="Call our voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
        values={{
          smsBody: hotlineSmsBodyText,
          'sms-link': (chunks) => (
            <a
              href={`sms://70888&body=${hotlineSmsBodyText}`}
              className="no-wrap"
              key="sms-link"
            >
              {chunks}
            </a>
          ),
          hotline: (
            <a href="tel:1-833-336-8683" className="no-wrap" key="hotline-link">
              833-336-8683
            </a>
          ),
        }}
        id="HllheH"
      />
    );
  }
}
