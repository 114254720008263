import * as React from 'react';

import type { ActiveLocalizedString } from '@dnc/baseline/utils/localization';

import { LocalizedMessage } from './LocalizedMessage';
import { COMPRESS_ON_NARROW_CLASS_NAMES } from './utils';

type BackupAlertBannerProps = {
  className: string;
  alert: ActiveLocalizedString;
};

export type BackupAlert = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  alertMessage: ActiveLocalizedString;
};

export const BackupAlertBanner: React.FC<BackupAlertBannerProps> = ({
  className,
  alert,
}) => {
  // We are using LocalizedMessage's html argument here
  // to be able to safely render <a> tags in a backup alert message.
  // This is safe, because the messages are configured by
  // the Voting Services team, and not direct user input.
  return (
    <LocalizedMessage
      html={alert}
      className={`${className} ${COMPRESS_ON_NARROW_CLASS_NAMES}`}
    />
  );
};
