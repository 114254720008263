import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import { type Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { UrlHelper } from '../../services/url-helper';

import { StateConfirmation } from './StateConfirmation';

/**
 * This ends up being a big chunk due to the widget code, so we want to load it
 * lazily only when needed.
 */
export const VepBackup = React.lazy(() => import('./VepWidgetBackup'));

/**
 * Enforces that a jurisdiction has been selected and a config has been loaded.
 *
 * If the former is not true, renders a {@link StateConfirmation} instead of
 * children. If the latter is not true, renders the VEP Widget (which uses VEP
 * API to get its data).
 *
 * Otherwise it calls its child function with the `jurisdiction` and
 * `jurisdictionConfig`.
 *
 * When the user selects a jurisdiction via this component, the selection is set
 * by doing a history replace with the new jurisdiction in the `state` query
 * parameter.
 */

export const RequireJurisdictionWrapper: React.FunctionComponent<{
  jurisdiction: Option<Jurisdiction>;
  jurisdictionConfig: Option<JurisdictionConfig>;
  children: (props: {
    jurisdiction: Jurisdiction;
    jurisdictionConfig: JurisdictionConfig;
  }) => React.ReactNode;
}> = ({ jurisdiction, jurisdictionConfig, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateJurisdiction = (j: Jurisdiction) => {
    navigate(
      UrlHelper.changeLocationParams(location, {
        [UrlHelper.JURISDICTION_PARAM]: j,
      }),
      {
        replace: true,
        preventScrollReset: true,
      }
    );
  };

  if (!jurisdiction) {
    return (
      <StateConfirmation
        jurisdiction={jurisdiction}
        updateJurisdiction={updateJurisdiction}
      />
    );
  } else if (!jurisdictionConfig) {
    // Having jurisdiction but no jurisdictionConfig is an indication that the
    // loader was unable to get info from Contentful. Since this is rare, we
    // lazy-load the <VepBackup> component so we don’t have to include it in the
    // complete bundle.
    return (
      <React.Suspense fallback={<div className="loading-spinner" />}>
        <VepBackup
          jurisdiction={jurisdiction}
          updateJurisdiction={updateJurisdiction}
        />
      </React.Suspense>
    );
  } else {
    return children({ jurisdiction, jurisdictionConfig });
  }
};
