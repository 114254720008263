import * as React from 'react';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import { JurisdictionSelect } from '@dnc/shared-components/jurisdiction-select';

type JurisdictionPickerProps = {
  selected: Jurisdiction;
  onChange(newValue: Jurisdiction): void;
};

/**
 * Very small wrapper around {@link JurisdictionSelect} that applies
 * styles from the "jurisdiction-picker" CSS class.
 */
export const JurisdictionPicker: React.FC<JurisdictionPickerProps> = (
  props
) => {
  const { onChange, selected } = props;

  const handleChange = (e: React.BaseSyntheticEvent) => {
    onChange(e.target.value);
  };

  if (!selected) {
    return null;
  }

  return (
    <div className="jurisdiction-picker">
      <JurisdictionSelect
        name="jurisdiction"
        className="select"
        onChange={handleChange}
        value={selected}
        residentPrefix={true}
      />
    </div>
  );
};
