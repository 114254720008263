import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  type Navigation,
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router';

import type {
  JurisdictionBallotRequestConfig,
  JurisdictionCustomLandingPageButtons,
  JurisdictionPollingLookupConfig,
  JurisdictionRegistrationConfig,
  JurisdictionVepConfig,
} from '@dnc/baseline/contentful/types';
import type { ElectionInfo } from '@dnc/baseline/data/election-types';
import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';
import { JurisdictionSettingsService } from '@dnc/baseline/services/jurisdiction-settings-service';
import type { JurisdictionButton } from '@dnc/baseline/utils/jurisdiction-button';
import {
  getLocalizedContent,
  type MinimumEnLocalizedString,
} from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';
import { LocalizedMessage } from '@dnc/shared-components/LocalizedMessage';

import { UrlHelper } from '../services/url-helper';

import { ActionButton, type ActionButtonType } from './ActionButton';
import { JurisdictionPicker } from './jurisdiction/JurisdictionPicker';

type ActionOptionsProps = {
  activeElection: Option<ElectionInfo>;
  jurisdiction: Jurisdiction;
  voterEdPageConfig: Option<JurisdictionVepConfig>;
  pollingLookupConfig: Option<JurisdictionPollingLookupConfig>;
  voterRegConfig: Option<JurisdictionRegistrationConfig>;
  ballotRequestConfig: Option<JurisdictionBallotRequestConfig>;
  jurisdictionConfigCustomButtons: Option<JurisdictionCustomLandingPageButtons>;

  navigationStateForTest?: Navigation['state'];
};

/**
 * This is the default order that our buttons appear on the page. Note that
 * individual buttons may not appear based on configuration.
 */
const DEFAULT_BUTTON_ORDER: ActionButtonType[] = [
  'locate',
  'custom-button-a',
  'custom-button-b',
  // “check registration”
  'confirm',
  'register',
  // “Vote by mail”
  'request-ballot',
  'in-person-info',
  'voter-education',
];

/**
 * Add per-state button order through here.
 */
const CUSTOM_BUTTON_ORDER: { [state in Jurisdiction]?: ActionButtonType[] } =
  {};

const ActionOptions: React.FC<ActionOptionsProps> = (props) => {
  const {
    activeElection,
    jurisdiction,
    voterEdPageConfig,
    pollingLookupConfig,
    voterRegConfig,
    ballotRequestConfig,
    navigationStateForTest,
    jurisdictionConfigCustomButtons,
  } = props;

  const jurisdictionSettings = React.useMemo(
    () => jurisdiction && JurisdictionSettingsService.getInfo(jurisdiction),
    [jurisdiction]
  );

  const isState = Jurisdictions.isState(jurisdiction);
  const isTerritory = Jurisdictions.isTerritory(jurisdiction);
  const locale = React.useContext(LocaleContext);
  const location = useLocation();
  const navigate = useNavigate();
  const urlHelper = UrlHelper.fromLocation(location);
  const navigation = useNavigation();

  /**
   * True if a navigation is in progress. We’ll be in this state while the
   * clientLoader of the next route (and loading any async JS) is happening.
   *
   * Can come up both from switching locales and staying on this page, and also
   * clicking a button to another page.
   */
  const isNavigating =
    (navigationStateForTest ?? navigation.state) === 'loading';

  /*
   Currently there are no existing custom
   buttons for a given jurisdiction. An example from:
   baseline/jurisdiction-info/external-links/custom-buttons.tsx
    case 'OH': {
      return [
        {
          analyticsKey: 'sample_ballot',
          kind: 'sample-ballot',
          name: {
            en: 'View your sample ballot',
            es: 'Ver una muestra de tu boleta',
          },
          url: jurisdictionURLs[jurisdiction].sampleBallotURL!,
        },
      ];
    }
  */
  const jurisdictionButtons = jurisdictionSettings?.customButtons?.map(
    (buttonData: JurisdictionButton) => (
      <ActionButton
        key={buttonData.kind}
        url={getLocalizedContent(locale, buttonData.url)}
        type={buttonData.analyticsKey}
        linkOptions={{ rel: 'external', target: '_blank' }}
        disabled={isNavigating}
      >
        <LocalizedMessage string={buttonData.name} />
      </ActionButton>
    )
  );

  const locateUrl = urlHelper.locateURL(jurisdiction);
  const urls = jurisdictionSettings?.urls;
  const checkRegistrationUrl =
    urls?.registrationCheckURL &&
    getLocalizedContent(locale, urls.registrationCheckURL);
  const registerUrl = urlHelper.registerURL(jurisdiction);
  const voterEducationStateUrl =
    isState && urlHelper.voterEducationStateURL(jurisdiction);
  const voterEducationTerritoryUrl =
    isTerritory && urlHelper.voterEducationTerritoryURL(jurisdiction);

  const customButtonAUrl =
    jurisdictionConfigCustomButtons?.customButtonAUrl &&
    getLocalizedContent(
      locale,
      jurisdictionConfigCustomButtons.customButtonAUrl
    );
  const customButtonAText = jurisdictionConfigCustomButtons?.customButtonAText;

  const customButtonBUrl =
    jurisdictionConfigCustomButtons?.customButtonBUrl &&
    getLocalizedContent(
      locale,
      jurisdictionConfigCustomButtons.customButtonBUrl
    );

  const customButtonBText = jurisdictionConfigCustomButtons?.customButtonBText;

  // This is for handling the rendering of the Contentful custom buttons A or B
  const renderCustomButtonAorB = (
    url: string,
    text: MinimumEnLocalizedString,
    key: string,
    type: ActionButtonType
  ) => {
    let isInternalLink: boolean;

    if (url.startsWith('https://iwillvote.com')) {
      isInternalLink = true;

      // Links in Contentful are hard-coded to direct to production. We remove
      // that so that they’re relative to both localhost and voyavotar.com.
      url = url.replace('https://iwillvote.com', '');
      // Include lang and other parameters we want to preserve.
      url = urlHelper.addSiteParamsToUrl(url);
    } else {
      isInternalLink = false;
    }
    // only open a new tab if the url is an external link
    const linkOptions = isInternalLink
      ? {}
      : {
          target: '_blank',
          rel: 'external noreferrer',
        };
    return (
      <ActionButton
        key={key}
        url={url}
        linkOptions={linkOptions}
        type={type}
        disabled={isNavigating}
      >
        {getLocalizedContent(locale, text)}
      </ActionButton>
    );
  };

  const renderBallotRequestButton = (
    ballotRequestConfig: JurisdictionBallotRequestConfig
  ) => {
    const pushToSosSiteExp =
      ballotRequestConfig.ballotRequestExperience === 'SOS website';
    const ballotRequestUrl = getLocalizedContent(
      locale,
      ballotRequestConfig.sosBallotRequestUrl
    );
    const linkOptions = pushToSosSiteExp
      ? { rel: 'external', target: '_blank' }
      : undefined;

    if (
      ballotRequestConfig.ballotRequestFlowEnabled &&
      ballotRequestConfig.ballotRequestButtonText
    ) {
      return (
        <ActionButton
          key="request-ballot"
          url={ballotRequestUrl}
          type="request-ballot"
          linkOptions={linkOptions}
          disabled={isNavigating}
        >
          <LocalizedMessage
            string={ballotRequestConfig.ballotRequestButtonText}
          />
        </ActionButton>
      );
    } else {
      return null;
    }
  };

  /* votingLocationLookup and registrationLookup
    are booleans in Contentful. Contentful only
    localizes the boolean values to 'en'. If the
    corresponding buttons are displayed, the
    intended en/es localization happens as expected.
    checking for an entry before checking for the
    booleans helps prevent the page from breaking
    while we are testing in Contentful with a limited
    list of jurisdictions.
  */

  const displayVotingLookup = pollingLookupConfig?.displayVotingLocationLookup;
  const displayRegLookup = voterRegConfig?.displayRegistrationLookup;

  const getLocateUrlForExperience = (
    pollingLookupConfig: JurisdictionPollingLookupConfig
  ) => {
    switch (pollingLookupConfig.lookupExperience) {
      case 'IWV Locate tool': {
        return locateUrl;
      }
      case 'SOS website': {
        if (pollingLookupConfig.sosLookupUrl) {
          return getLocalizedContent(locale, pollingLookupConfig.sosLookupUrl);
        } else {
          return undefined;
        }
      }
      case 'none': {
        return undefined;
      }
    }
  };

  const renderLocationLookupButton = (
    pollingLookupConfig: JurisdictionPollingLookupConfig
  ) => {
    const locationLookupUrl = getLocateUrlForExperience(pollingLookupConfig);
    const linkOptions =
      pollingLookupConfig.lookupExperience === 'SOS website'
        ? { rel: 'external', target: '_blank' }
        : undefined;
    if (locationLookupUrl && displayVotingLookup) {
      return (
        <ActionButton
          key="locate"
          buttonClass="button--cta"
          checkmark
          url={locationLookupUrl}
          type="locate"
          linkOptions={linkOptions}
          disabled={isNavigating}
        >
          {activeElection?.electionType === 'caucus' ? (
            <FormattedMessage
              defaultMessage="Confirm where I'll caucus"
              id="options.lookup.caucus"
              tagName="span"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Find voting or drop-off location"
              id="options.lookup.notCaucus"
              tagName="span"
            />
          )}
        </ActionButton>
      );
    } else {
      return null;
    }
  };

  const [searchParams] = useSearchParams();
  const actionKitSuccessParam = searchParams.get('commit_form_success');

  const buttonOrder =
    (jurisdiction && CUSTOM_BUTTON_ORDER[jurisdiction]) || DEFAULT_BUTTON_ORDER;

  const standardButtons = buttonOrder.map((type) => {
    switch (type) {
      case 'locate':
        return (
          isState &&
          pollingLookupConfig &&
          renderLocationLookupButton(pollingLookupConfig)
        );

      case 'custom-button-a':
        return (
          customButtonAUrl &&
          customButtonAText &&
          renderCustomButtonAorB(
            customButtonAUrl,
            customButtonAText,
            'custom-button-a',
            'custom-button-a'
          )
        );

      case 'custom-button-b':
        return (
          customButtonBUrl &&
          customButtonBText &&
          renderCustomButtonAorB(
            customButtonBUrl,
            customButtonBText,
            'custom-button-b',
            'custom-button-b'
          )
        );

      case 'confirm':
        return (
          displayRegLookup &&
          checkRegistrationUrl && (
            <ActionButton
              key="confirm"
              url={checkRegistrationUrl}
              type="confirm"
              linkOptions={{ rel: 'external', target: '_blank' }}
              disabled={isNavigating}
            >
              <FormattedMessage
                defaultMessage="Check if I’m registered to vote"
                id="options.checkRegistration"
                tagName="span"
              />
            </ActionButton>
          )
        );

      case 'register':
        return (
          isState &&
          voterRegConfig?.registrationFlowEnabled && (
            <ActionButton
              key="register"
              url={registerUrl}
              type="register"
              disabled={isNavigating}
            >
              <FormattedMessage
                defaultMessage="Register to vote"
                id="options.register"
                tagName="span"
              />
            </ActionButton>
          )
        );

      case 'request-ballot':
        return (
          isState &&
          ballotRequestConfig &&
          renderBallotRequestButton(ballotRequestConfig)
        );

      case 'voter-education':
        if (voterEducationStateUrl && voterEdPageConfig?.vepEnabled) {
          return (
            <ActionButton
              key="voter-education"
              url={voterEducationStateUrl}
              type="voter-education"
              disabled={isNavigating}
            >
              <FormattedMessage
                defaultMessage="Learn more about voting"
                id="options.voterEducation.button"
                tagName="span"
              />
            </ActionButton>
          );
        } else if (
          voterEducationTerritoryUrl &&
          voterEdPageConfig?.vepEnabled
        ) {
          return (
            <ActionButton
              key="voter-education"
              url={voterEducationTerritoryUrl}
              type="voter-education"
              disabled={isNavigating}
            >
              <FormattedMessage
                defaultMessage="Learn more about voting"
                id="options.voterEducation.button"
                tagName="span"
              />
            </ActionButton>
          );
        } else {
          return null;
        }

      default:
        return null;
    }
  });

  return (
    <div>
      {actionKitSuccessParam && (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">
            <FormattedMessage
              defaultMessage="Thanks for committing to vote! Find all the information you need to vote in {jurisdiction}."
              values={{
                jurisdiction: jurisdiction,
              }}
              id="fby4I+"
              tagName="span"
            />
          </span>
        </div>
      )}
      <h1>
        <FormattedMessage
          defaultMessage="Let’s Vote"
          id="options.header"
          tagName="span"
        />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Select your state, make sure you’re registered to vote, then choose how you’re going to vote this year."
          id="options.subheader"
          tagName="span"
        />
      </p>
      <JurisdictionPicker
        selected={jurisdiction}
        onChange={(j) => navigate(urlHelper.homeURL(j))}
      />
      <section>
        {standardButtons}

        {jurisdictionButtons}
      </section>
    </div>
  );
};

export default ActionOptions;
