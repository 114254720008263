import type { Location } from 'react-router';

import type { ActionButtonType } from '../../components/ActionButton';
import { parseQueryString } from '../../services/utils';
import type { Jurisdiction } from '../data/jurisdictions';
import type { ActiveLocale } from '../utils/localization';
import type { Option } from '../utils/option';
import { assertNever } from '../utils/type-utils';
import type { NormalizedAddress } from '../utils/voter-address';

import type { GoogleAnalyticsEvent } from './analytics-event';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export type UTMFields = {
  partnerId: string;
  partnerCampaign: string;
  partnerContent: string;
  partnerCustom: string;
  partnerMedium: string;
  partnerTerm: string;
  appIntent?: string;
};

/**
 * `'user_data'` type for the values we need to send to allow for “enhanced
 * conversion tracking.”
 *
 * @see
 * https://support.google.com/google-ads/answer/13258081#zippy=%2Cidentify-and-define-your-enhanced-conversions-fields
 */
type GoogleEnhancedConversionUserData = {
  email: string;
  address: {
    street: string;
    city: string;
    region: string;
    postal_code: string;
    country: string;
  };
};

export interface AnalyticsAdapter {
  setJurisdiction(jurisdiction: Option<Jurisdiction>): void;
  setLocale(locale: ActiveLocale): void;

  routeChanged(
    location: Location,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ): void;
  recordEvent(event: GoogleAnalyticsEvent): Promise<void>;
  recordConversion(
    to: string,
    extras?: { [key: string]: string | number | boolean }
  ): void;

  setUserData(data: GoogleEnhancedConversionUserData): void;
}

type EventOptions = {
  category: string;
  action: string;
  label?: string;
};

type ClickedEventOptions = {
  category: string;
  target: string;
  isExternal: boolean;
};

type TimedEventOptions = {
  category: string;
  label: string;
  value: number;
  variable: string;
};

type IwvConversion =
  | { type: 'ActionOptionsClick'; analyticsKey: ActionButtonType }
  | { type: 'LocationSearchSuccess'; address: NormalizedAddress };

/**
 * Service to send web analytics to a provider of our choice.
 *
 * Uses the adapter pattern so that it can have helper methods (like
 * {@link #clicked}) that don’t have to be re-implemented in each adaptor. But
 * overall doesn’t need to be too general because we really only have one place
 * we send analytics.
 */
export class AnalyticsService {
  constructor(private adapter: AnalyticsAdapter) {}

  public setLocale(locale: ActiveLocale) {
    this.adapter.setLocale(locale);
  }

  public setJurisdiction(jurisdiction: Option<Jurisdiction>) {
    this.adapter.setJurisdiction(jurisdiction);
  }

  public routeChanged(
    location: Location,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ) {
    this.adapter.routeChanged(location, locale, jurisdiction);
  }

  public event({ category, action, label }: EventOptions): Promise<void> {
    return this.adapter.recordEvent({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      ...(label ? { eventLabel: label } : {}),
    });
  }

  public recordConversion(conversion: IwvConversion) {
    // Apologies for terse variable names, they keep things on one line in the
    // switch statements.

    /** AdWords account number for DCS digital ads. HFP 2024. */
    const dcsAcct = 'AW-16725117467';

    /** Conversion ID for generic ad conversions. */
    const dcsGenericConv = 'nQuyCJSY59kZEJuUlKc-';

    switch (conversion.type) {
      case 'ActionOptionsClick':
        switch (conversion.analyticsKey) {
          case 'locate':
            this.adapter.recordConversion(`${dcsAcct}/${dcsGenericConv}`);
            this.adapter.recordConversion(`${dcsAcct}/oKiQCKu2tNwZEJuUlKc-`);
            break;

          case 'register':
            // We do _not_ want to trigger the generic conversion for register
            this.adapter.recordConversion(`${dcsAcct}/nflECK62tNwZEJuUlKc-`);
            break;

          case 'voter-education':
            // We do _not_ want to trigger the generic conversion for VEP
            this.adapter.recordConversion(`${dcsAcct}/PIHnCLG2tNwZEJuUlKc-`);
            break;

          // “Vote by Mail” button
          case 'request-ballot':
            this.adapter.recordConversion(`${dcsAcct}/${dcsGenericConv}`);
            this.adapter.recordConversion(`${dcsAcct}/k29QCOS6q9wZEJuUlKc-`);
            break;

          // “Check registration” button
          case 'confirm':
            this.adapter.recordConversion(`${dcsAcct}/${dcsGenericConv}`);
            this.adapter.recordConversion(`${dcsAcct}/bz7MCOe6q9wZEJuUlKc-`);
            break;

          case 'in-person-info':
            this.adapter.recordConversion(`${dcsAcct}/${dcsGenericConv}`);
            this.adapter.recordConversion(`${dcsAcct}/1CC0CJeGqt0ZEJuUlKc-`);
            break;

          default:
          // ignore unconfigured keys
        }
        break;

      case 'LocationSearchSuccess':
        // Enables “enhanced conversion tracking”. gtag will automatically hash
        // the PII for this (email address, and street).
        //
        // https://support.google.com/google-ads/answer/13258081
        this.adapter.setUserData({
          // GIANT HACK
          //
          // We don’t have an email address for the user, so instead
          // we make up a string and append '@iwillvote.com'. gtag will hash this
          // value and tell Google that it’s the hash of an email address.
          // Google obviously won’t be able to match it to the email address of
          // a Google Account, but that’s fine, it will (hopefully) match on
          // address instead.
          //
          // (Based on conversations with Tyler Davis of DCS.)
          email:
            [
              conversion.address.addressLine1,
              conversion.address.city,
              conversion.address.stateCode,
              conversion.address.zip,
            ]
              .join('')
              .replace(/[\s@]/g, '') + '@iwillvote.com', // string must be valid email address
          address: {
            street: conversion.address.addressLine1,
            city: conversion.address.city ?? '',
            region: conversion.address.stateCode,
            postal_code: conversion.address.zip,
            country: 'USA',
          },
        });

        this.adapter.recordConversion(`${dcsAcct}/${dcsGenericConv}`);
        this.adapter.recordConversion(`${dcsAcct}/VyItCOu5tdwZEJuUlKc-`);
        this.adapter.recordConversion(`${dcsAcct}/x8JUCOq6q9wZEJuUlKc-`, {
          value: 1.0,
          currency: 'USD',
        });
        break;

      default:
        assertNever(conversion);
    }
  }

  public clicked({
    category,
    target,
    isExternal,
  }: ClickedEventOptions): Promise<void> {
    if (isExternal) {
      return this.adapter.recordEvent({
        hitType: 'event',
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: target,
      });
    } else {
      return this.adapter.recordEvent({
        hitType: 'event',
        eventCategory: category,
        eventAction: 'click',
        eventLabel: target,
      });
    }
  }

  public timed({
    category,
    label,
    value,
    variable,
  }: TimedEventOptions): Promise<void> {
    return this.adapter.recordEvent({
      hitType: 'timing',
      timingCategory: category,
      timingLabel: label,
      timingValue: value,
      timingVar: variable,
    });
  }
}

/**
 * Implementation for tests that just swallows the events and doesn’t do
 * anything with them.
 *
 * We include the parameters so that subclasses can override this with
 * partial implementations.
 */
export class NullAnalyticsAdapter implements AnalyticsAdapter {
  setUserId(_: string) {}

  setJurisdiction(_: Option<Jurisdiction>) {}
  setLocale(_: ActiveLocale) {}

  routeChanged(_: Location) {}

  recordEvent(_: GoogleAnalyticsEvent) {
    return Promise.resolve();
  }

  recordConversion(_: string): void {}
  setUserData(_: GoogleEnhancedConversionUserData): void {}
}

/**
 * Default adaptor that sends information to Google Analytics.
 */
export class GoogleAnalyticsAdapter implements AnalyticsAdapter {
  public setJurisdiction(jurisdiction: Option<Jurisdiction>): void {
    const analyticsJurisdiction = jurisdiction ?? '[unknown]';
    gtag('set', 'jurisdiction', analyticsJurisdiction);
  }

  public setLocale(locale: ActiveLocale): void {
    gtag('set', 'lang', locale);
  }

  public routeChanged(
    location: Location<unknown>,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ) {
    const params = parseQueryString(location.search);

    // We set these dynamically in case any of our redirects has added them to
    // the URL. (For example, short URLs that we print in mailers.)
    //
    // See:
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#campaignSource
    if (params['utm_source']) {
      gtag('set', { campaign_source: params['utm_source'] });
    }
    if (params['utm_medium']) {
      gtag('set', { campaign_medium: params['utm_medium'] });
    }
    if (params['utm_campaign']) {
      gtag('set', { campaign_name: params['utm_campaign'] });
    }
    if (params['utm_term']) {
      gtag('set', { campaign_term: params['utm_term'] });
    }
    if (params['utm_content']) {
      gtag('set', { campaign_content: params['utm_content'] });
    }

    // We explicitly just send the pathname as the page to remove any query
    // parameters, which are not interesting for analytics and/or have PII.
    gtag('event', 'page_view', {
      page_location: location.pathname,
      jurisdiction: jurisdiction ?? '[unknown]',
      lang: locale,
      // specifically send this to "analytics"
      // instead of "adwords"
      send_to: 'analytics',
    });
  }

  public recordEvent(event: GoogleAnalyticsEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      gtag('event', 'record_event', {
        ...event,
        hitCallback: resolve,
        hitCallbackFail: reject,
        // specifically send this to "analytics"
        // instead of "adwords"
        send_to: 'analytics',
      });
    });
  }

  public recordConversion(
    to: string,
    extras?: { [key: string]: string | number | boolean }
  ): void {
    gtag('event', 'conversion', { send_to: to, ...extras });
  }

  public setUserData(data: GoogleEnhancedConversionUserData): void {
    gtag('set', 'user_data', data);
  }
}
