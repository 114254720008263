import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';

/**
 * Our entry point for the client bundle.
 */
async function startApp() {
  if (import.meta.env.DEV) {
    // We install MSW so we can intercept location lookup requests and serve
    // fixture data for them, which is useful during times when there are no
    // active elections.
    const { worker } = await import('./msw/browser');

    try {
      await worker.start({
        // We want most of the app’s requests to happen without being intercepted,
        // so this suppresses the warnings when they happen.
        onUnhandledRequest: 'bypass',
      });
    } catch (e) {
      console.error(
        'MSW failed to start. Fixture VIS responses will not be available.',
        e
      );
    }
  }

  ReactDOM.hydrateRoot(
    document,
    <React.StrictMode>
      <HydratedRouter />
    </React.StrictMode>,
    {
      // "as any" on these handlers to make them compatible with
      // exactOptionalPropertyTypes
      onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
        console.warn('Uncaught error', error, errorInfo.componentStack);
      }) as any,
      // Callback called when React catches an error in an Error Boundary.
      onCaughtError: Sentry.reactErrorHandler() as any,
      // Callback called when React automatically recovers from errors.
      onRecoverableError: Sentry.reactErrorHandler(),
    }
  );
}

startApp();
