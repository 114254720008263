import * as React from 'react';

import type {
  JurisdictionHotlineFooterOverride,
  JurisdictionLocaleSupport,
} from '@dnc/baseline/contentful/types';
import {
  DEFAULT_LOCALES,
  localeReadsRightToLeft,
} from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';

import { useLocale } from '@dnc/shared-components/LocaleContext';

import Footer from './Footer';
import SiteBanners, { type Alerts } from './SiteBanners';
import { SkipToMainContentLink } from './SkipToMainContentLink';
import { SiteLanguageMenu } from './language/SiteLanguageMenu';

/**
 * Renders the most common outer shell of our pages, which is a skip-to-content
 * link, language menu, and footer at the bottom. Sets up locale props of `lang`
 * and `dir` as well.
 *
 * Also renders any alert banners, based on the `alerts` prop.
 *
 * It’s expected that the `children` prop will be an {@link IwlLayout} or
 * {@link LocateLayout}.
 */
export default function PageLayout({
  hotlineFooterOverride,
  alerts,
  supportedLocales = DEFAULT_LOCALES,
  priorityLocales = DEFAULT_LOCALES,
  children,
}: {
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  supportedLocales: JurisdictionLocaleSupport | undefined;
  priorityLocales: JurisdictionLocaleSupport | undefined;
  alerts: Alerts;
  children?: React.ReactNode;
}): React.ReactNode {
  const currentLocale = useLocale();
  const displayRightToLeft = localeReadsRightToLeft(currentLocale);

  return (
    <div lang={currentLocale} dir={displayRightToLeft ? 'rtl' : ''}>
      <SkipToMainContentLink />

      <SiteLanguageMenu
        currentLocale={currentLocale}
        supportedLocales={supportedLocales}
        priorityLocales={priorityLocales}
      />

      <SiteBanners alerts={alerts} />

      {children}

      <Footer hotlineFooterOverride={hotlineFooterOverride} />
    </div>
  );
}
