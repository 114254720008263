import * as React from 'react';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import PageLayout from '../PageLayout';
import { type Alerts } from '../SiteBanners';

import IwvLayout from './IwvLayout';

/**
 * Boilerplate component to combine {@link PageLayout} and {@link IwvLayout}
 * together to render the standard 2-column IWV page.
 */
export default function IwvPage({
  alerts,
  jurisdiction,
  jurisdictionConfig,
  children,
}: {
  jurisdiction: Option<Jurisdiction>;
  jurisdictionConfig: Option<
    Pick<
      JurisdictionConfig,
      | 'hotlineFooterOverride'
      | 'priorityLocales'
      | 'supportedLocales'
      | 'voterHotline'
    >
  >;
  alerts: Alerts;
  children: React.ReactNode;
}): React.ReactNode {
  return (
    <PageLayout
      alerts={alerts}
      hotlineFooterOverride={jurisdictionConfig?.hotlineFooterOverride}
      priorityLocales={jurisdictionConfig?.priorityLocales}
      supportedLocales={jurisdictionConfig?.supportedLocales}
    >
      <IwvLayout
        jurisdiction={jurisdiction}
        customVoterHotline={jurisdictionConfig?.voterHotline}
        hotlineFooterOverride={jurisdictionConfig?.hotlineFooterOverride}
      >
        {children}
      </IwvLayout>
    </PageLayout>
  );
}
