import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

/**
 * Fields type definition for content type 'TypeCustomPage'
 * @name TypeCustomPageFields
 * @type {TypeCustomPageFields}
 * @memberof TypeCustomPage
 */
export interface TypeCustomPageFields {
    /**
     * Field type definition for field 'internalPageTitle' (Internal Page Title)
     * @name Internal Page Title
     * @localized false
     * @summary An internal name for this page for the Contentful list view, since slug or page title may not be unique. Not rendered on IWV, though technically public in the API so no swears.
     */
    internalPageTitle: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'pageTitle' (Page Title)
     * @name Page Title
     * @localized true
     * @summary The page title will appear as the top headline for a custom page.
     */
    pageTitle: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized false
     * @summary All lowercase, use hyphens instead of spaces. Please keep unique for the state!
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'stateCode' (State Code)
     * @name State Code
     * @localized false
     */
    stateCode: EntryFieldTypes.Symbol<"AK" | "AL" | "AR" | "AZ" | "CA" | "CO" | "CT" | "DC" | "DE" | "FL" | "GA" | "HI" | "IA" | "ID" | "IL" | "IN" | "KS" | "KY" | "LA" | "MA" | "MD" | "ME" | "MI" | "MN" | "MO" | "MS" | "MT" | "NC" | "ND" | "NE" | "NH" | "NJ" | "NM" | "NV" | "NY" | "OH" | "OK" | "OR" | "PA" | "RI" | "SC" | "SD" | "TEST-ONLY" | "TN" | "TX" | "UT" | "VA" | "VT" | "WA" | "WI" | "WV" | "WY">;
    /**
     * Field type definition for field 'pageEnabled' (Page Enabled)
     * @name Page Enabled
     * @localized false
     * @summary Should this page be turned on/accessible to IWV?
     */
    pageEnabled: EntryFieldTypes.Boolean;
    /**
     * Field type definition for field 'pageContent' (Page Content)
     * @name Page Content
     * @localized true
     */
    pageContent: EntryFieldTypes.RichText;
}

/**
 * Entry skeleton type definition for content type 'customPage' (Custom Page)
 * @name TypeCustomPageSkeleton
 * @type {TypeCustomPageSkeleton}
 * @author 5JHv6AkDCK7JOmEeeFEGvf
 * @since 2024-10-15T17:41:52.924Z
 * @version 11
 */
export type TypeCustomPageSkeleton = EntrySkeletonType<TypeCustomPageFields, "customPage">;
/**
 * Entry type definition for content type 'customPage' (Custom Page)
 * @name TypeCustomPage
 * @type {TypeCustomPage}
 * @author 5JHv6AkDCK7JOmEeeFEGvf
 * @since 2024-10-15T17:41:52.924Z
 * @version 11
 */
export type TypeCustomPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeCustomPageSkeleton, Modifiers, Locales>;

export function isTypeCustomPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeCustomPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'customPage'
}

export type TypeCustomPageWithoutLinkResolutionResponse = TypeCustomPage<"WITHOUT_LINK_RESOLUTION">;
export type TypeCustomPageWithoutUnresolvableLinksResponse = TypeCustomPage<"WITHOUT_UNRESOLVABLE_LINKS">;
export type TypeCustomPageWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeCustomPage<"WITH_ALL_LOCALES", Locales>;
export type TypeCustomPageWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> = TypeCustomPage<"WITHOUT_LINK_RESOLUTION" | "WITH_ALL_LOCALES", Locales>;
export type TypeCustomPageWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> = TypeCustomPage<"WITHOUT_UNRESOLVABLE_LINKS" | "WITH_ALL_LOCALES", Locales>;
