import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

/**
 * Fields type definition for content type 'TypeVoteByMailNote'
 * @name TypeVoteByMailNoteFields
 * @type {TypeVoteByMailNoteFields}
 * @memberof TypeVoteByMailNote
 */
export interface TypeVoteByMailNoteFields {
    /**
     * Field type definition for field 'internalTitle' (Internal Title)
     * @name Internal Title
     * @localized false
     */
    internalTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'noteCopy' (Note Copy)
     * @name Note Copy
     * @localized true
     */
    noteCopy?: EntryFieldTypes.RichText;
}

/**
 * Entry skeleton type definition for content type 'voteByMailNote' (Vote By Mail Note)
 * @name TypeVoteByMailNoteSkeleton
 * @type {TypeVoteByMailNoteSkeleton}
 * @author 5JHv6AkDCK7JOmEeeFEGvf
 * @since 2022-11-10T19:47:48.091Z
 * @version 1
 */
export type TypeVoteByMailNoteSkeleton = EntrySkeletonType<TypeVoteByMailNoteFields, "voteByMailNote">;
/**
 * Entry type definition for content type 'voteByMailNote' (Vote By Mail Note)
 * @name TypeVoteByMailNote
 * @type {TypeVoteByMailNote}
 * @author 5JHv6AkDCK7JOmEeeFEGvf
 * @since 2022-11-10T19:47:48.091Z
 * @version 1
 */
export type TypeVoteByMailNote<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeVoteByMailNoteSkeleton, Modifiers, Locales>;

export function isTypeVoteByMailNote<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeVoteByMailNote<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'voteByMailNote'
}

export type TypeVoteByMailNoteWithoutLinkResolutionResponse = TypeVoteByMailNote<"WITHOUT_LINK_RESOLUTION">;
export type TypeVoteByMailNoteWithoutUnresolvableLinksResponse = TypeVoteByMailNote<"WITHOUT_UNRESOLVABLE_LINKS">;
export type TypeVoteByMailNoteWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeVoteByMailNote<"WITH_ALL_LOCALES", Locales>;
export type TypeVoteByMailNoteWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> = TypeVoteByMailNote<"WITHOUT_LINK_RESOLUTION" | "WITH_ALL_LOCALES", Locales>;
export type TypeVoteByMailNoteWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> = TypeVoteByMailNote<"WITHOUT_UNRESOLVABLE_LINKS" | "WITH_ALL_LOCALES", Locales>;
