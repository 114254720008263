import * as React from 'react';

import ActionOptions from '../components/ActionOptions';
import { RequireJurisdictionWrapper } from '../components/jurisdiction/RequireJurisdictionWrapper';
import { useAlerts } from '../components/layout/SiteBanners';
import IwvPage from '../components/layout/iwv/IwvPage';
import {
  type OptionalLoadedJurisdictionInfo,
  loadJurisdictionFromSearchQuery,
} from '../loaders/loadJurisdictionFromSearchQuery';

import { getGlobalServices } from '../services/services';

import type { Route } from './+types/_index';

export async function clientLoader(
  arg: Route.ClientLoaderArgs
): Promise<OptionalLoadedJurisdictionInfo> {
  const services = getGlobalServices();

  return loadJurisdictionFromSearchQuery(services, arg);
}

export default function HomePage({
  loaderData: { jurisdiction, jurisdictionConfig },
}: Route.ComponentProps): React.ReactNode {
  const services = getGlobalServices();
  const alerts = useAlerts(services, jurisdictionConfig);

  return (
    <IwvPage
      jurisdiction={jurisdiction}
      jurisdictionConfig={jurisdictionConfig}
      alerts={alerts}
    >
      <RequireJurisdictionWrapper
        jurisdiction={jurisdiction}
        jurisdictionConfig={jurisdictionConfig}
      >
        {({ jurisdiction, jurisdictionConfig }) => (
          <ActionOptions
            jurisdiction={jurisdiction}
            activeElection={jurisdictionConfig.electionInfo}
            voterRegConfig={jurisdictionConfig.registrationConfig}
            voterEdPageConfig={jurisdictionConfig.vepConfig}
            pollingLookupConfig={jurisdictionConfig.pollingLocationLookupConfig}
            ballotRequestConfig={jurisdictionConfig.ballotRequestConfig}
            jurisdictionConfigCustomButtons={
              jurisdictionConfig.customLandingPageButtons
            }
          />
        )}
      </RequireJurisdictionWrapper>
    </IwvPage>
  );
}
