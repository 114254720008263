import React from 'react';
import { Link } from 'react-router';

import EnLogo from '../../assets/i-will-vote-logo-we.svg?react';

const ErrorBoundary: React.FC = () => {
  return (
    <div className="page-wrap">
      <header className="header">
        <div className="header__inner">
          <Link role="banner" to={'/'} aria-label="IWillVote.com" tabIndex={0}>
            <EnLogo aria-label="IWillVote.com" className="logo header__logo" />
          </Link>
        </div>
      </header>

      <div id="main-content" className="text-center content-wrap">
        <h1>Error</h1>
        <h2>Oops! Looks like there was an error.</h2>
      </div>
    </div>
  );
};

export default ErrorBoundary;
