export const COMPRESS_ON_NARROW_CLASS_NAMES =
  'leading-tight sm:leading-normal [&_p]:text-xs sm:[&_p]:text-base';

/** Commonly used external link icon classes
 * - max height/width: 1rem
 * - display inline
 * - vertical align on baseline
 * - color is current text color
 */
export const COMMON_EXTERNAL_LINK_ICON_CLASS_NAMES =
  'max-h-4 max-w-4 inline align-baseline fill-current';

/** Commonly used warning icon classes
 * - max height/width: 1rem
 * - display inline
 * - vertical align subscript baseline
 */
export const COMMON_WARNING_ICON_CLASS_NAMES =
  'align-sub inline max-h-4 max-w-4 sm:max-h-6 sm:max-w-6';
