import * as React from 'react';

import {
  getLocalizedContent,
  type MinimumEnLocalizedString,
} from '@dnc/baseline/utils/localization';

import { LocaleContext } from './LocaleContext';

type LocalizedMessageProps = {
  tabIndex?: number;
  className?: string;
} & (
  | {
      string: MinimumEnLocalizedString;
      html?: undefined;
    }
  | { string?: undefined; html: MinimumEnLocalizedString }
);

/**
 * Renders a {@link MinimumEnLocalizedString} value in the user’s current locale.
 *
 * If the value is passed to the `html` property, it’s rendered using
 * `dangerouslySetInnerHTML`. So make sure that’s safe.
 *
 * @see LocaleContext
 */
const LocalizedMessage: React.FC<LocalizedMessageProps> = ({
  tabIndex,
  className,
  string,
  html,
}) => {
  const locale = React.useContext(LocaleContext);

  if (html !== undefined) {
    return (
      <span
        className={className}
        tabIndex={tabIndex}
        // This doesn't render user-defined content, so it should
        // be relatively low risk
        dangerouslySetInnerHTML={{ __html: getLocalizedContent(locale, html) }}
      />
    );
  } else {
    return (
      <span className={className} tabIndex={tabIndex}>
        {getLocalizedContent(locale, string)}
      </span>
    );
  }
};

export { LocalizedMessage };
