import * as React from 'react';

import {
  localized,
  type LinkedAlertFields,
} from '@dnc/baseline/contentful/types';

import { LocalizedRichText } from './LocalizedRichText';
import { COMPRESS_ON_NARROW_CLASS_NAMES } from './utils';

type RichTextAlertBannerProps = {
  className: string;
  alert: LinkedAlertFields;
};

export const RichTextAlertBanner: React.FC<RichTextAlertBannerProps> = ({
  className,
  alert,
}) => {
  return (
    <LocalizedRichText
      richTextBlock={localized(alert.alertMessage)}
      className={`${className} ${COMPRESS_ON_NARROW_CLASS_NAMES}`}
    />
  );
};
