import * as Sentry from '@sentry/react';
// Need to import this as * because of bundler problems trying to import
// { createClient } from 'contentful'; thx esm interop.
import * as contentful from 'contentful';
import moment from 'moment';

import type { TypeActiveElectionWithAllLocalesResponse } from '../../types/contentful.auto/TypeActiveElection';
import { isTypeCustomPage } from '../../types/contentful.auto/TypeCustomPage';
import {
  isTypeElectionDate,
  type TypeElectionDateFields,
  type TypeElectionDateSkeleton,
  type TypeElectionDateWithAllLocalesResponse,
} from '../../types/contentful.auto/TypeElectionDate';
import type {
  TypeJurisdictionConfigSkeleton,
  TypeJurisdictionConfigWithAllLocalesResponse,
} from '../../types/contentful.auto/TypeJurisdictionConfig';
import type { TypeSitewideAlertsSkeleton } from '../../types/contentful.auto/TypeSitewideAlerts';
import type {
  TypeTerritoryConfigSkeleton,
  TypeTerritoryConfigWithAllLocalesResponse,
} from '../../types/contentful.auto/TypeTerritoryConfig';
import {
  isTypeVoteByMailNote,
  type TypeVoteByMailNoteSkeleton,
} from '../../types/contentful.auto/TypeVoteByMailNote';

import {
  ProductionJurisdictionConfigEntries,
  TestingJurisdictionConfigEntries,
} from '../contentful/jurisdiction-entries';
import {
  ProductionTerritoryConfigEntries,
  TestingTerritoryConfigEntries,
} from '../contentful/territory-entries';

import {
  type JurisdictionLocateMessage,
  type LinkedAlertFields,
  type ImportantDates,
  type SitewideAlertsObject,
  type JurisdictionRegistrationConfig,
  type JurisdictionConfig,
  type JurisdictionVepConfig,
  type JurisdictionPollingLookupConfig,
  type JurisdictionBallotRequestConfig,
  type TerritoryConfig,
  type NationalBooleanConfig,
  type NationalLocalesConfig,
  type JurisdictionBooleanConfig,
  type JurisdictionLocaleSupport,
  type VepBooleans,
  type JurisdictionHotlineFooterOverride,
  type JurisdictionCustomLandingPageButtons,
  type JurisdictionCustomPage,
  FALLBACK_VEP_CONFIG_OBJECT,
  isEntry,
  notLocalized,
  localized,
} from '../contentful/types';
import type { ElectionInfo } from '../data/election-types';
import type { Territory, Jurisdiction, State } from '../data/jurisdictions';
import {
  ActiveLanguageToLocale,
  DEFAULT_LOCALES,
  DefaultLanguageToLocale,
  hasDefaultLocales,
  hasEnglishLocale,
  isActiveLanguage,
  type ActiveLocale,
  type LocalizedRichTextObj,
  type LocalizedString,
  type ActiveLocalizedRichTextObj,
  type ActiveLocalizedString,
} from '../utils/localization';
import type { Option } from '../utils/option';

/**
 * The fields from the Jurisdiction Config model from contentful.
 */
export type ContentfulJurisdictionConfig =
  TypeJurisdictionConfigWithAllLocalesResponse<ActiveLocale>['fields'];

/**
 * The fields from the Territory Config model from contentful.
 */
export type ContentfulTerritoryConfig =
  TypeTerritoryConfigWithAllLocalesResponse<ActiveLocale>['fields'];

/*
 Contentful is a content management service
 Contentful will be the home for voter education
 information, replacing the information available
 in @dnc/baseline. This will allow for faster
 updates/deployment without engineer involvement.
*/

function dateStringToMoment(electionDate: string): moment.Moment;
function dateStringToMoment(
  electionDate: string | undefined
): moment.Moment | undefined;
function dateStringToMoment(
  electionDate: string | undefined
): moment.Moment | undefined {
  return electionDate !== undefined ? moment(electionDate) : undefined;
}

/**
 * Either a short or long note (likely for a date).
 *
 * If provided, will have both "en" and "es" fields based on checking in
 * {@link parseContentfulNote}.
 */
export type ParsedNote =
  | {
      type: 'short';
      note: ActiveLocalizedString;
    }
  | {
      type: 'long';
      note: ActiveLocalizedRichTextObj;
    };

/**
 * @returns A {@link ParsedNote} if there are both EN and ES translations for
 * the given note objects. Prioritizes the short note over the long note.
 */
export const parseContentfulNote = (
  note: LocalizedString | undefined,
  longNote: LocalizedRichTextObj | undefined
): Option<ParsedNote> => {
  // We prioritize short note over long note.
  if (note?.en?.length && note?.es?.length) {
    // Little bit of assignment hackery for TS to understand that note.en and
    // note.es exist.
    return {
      type: 'short',
      note: { ...note, en: note.en, es: note.es },
    };
  } else if (longNote?.en?.content.length && longNote?.es?.content.length) {
    return {
      type: 'long',
      note: { ...longNote, en: longNote.en, es: longNote.es },
    };
  }
  return undefined;
};

const parseElectionDates = (
  dates: ContentfulDates,
  earlyVotingStartIsSameStatewide: boolean
): ImportantDates => ({
  earlyVotingStartBy: dateStringToMoment(
    notLocalized(dates.earlyVotingStartBy?.electionDate)
  ),
  earlyVotingStartByNote: parseContentfulNote(
    dates.earlyVotingStartBy?.note,
    dates.earlyVotingStartBy?.longNote
  ),
  earlyVotingStartIsSameStatewide,
  inPersonAbsenteeStartBy: dateStringToMoment(
    notLocalized(dates.inPersonAbsenteeStartBy?.electionDate)
  ),
  inPersonAbsenteeStartByNote: parseContentfulNote(
    dates.inPersonAbsenteeStartBy?.note,
    dates.inPersonAbsenteeStartBy?.longNote
  ),
  electionDay: dateStringToMoment(
    notLocalized(dates.electionDay?.electionDate)
  ),
  electionDayStatewideHours: parseContentfulNote(
    dates.electionDayStatewideHours?.note,
    dates.electionDayStatewideHours?.longNote
  ),
  ballotRequestBy: dateStringToMoment(
    notLocalized(dates.requestBallotBy?.electionDate)
  ),
  ballotRequestByNote: parseContentfulNote(
    dates.requestBallotBy?.note,
    dates.requestBallotBy?.longNote
  ),
  ballotDropoffBy: dateStringToMoment(
    notLocalized(dates.dropoffBallotBy?.electionDate)
  ),
  ballotDropoffByNote: parseContentfulNote(
    dates.dropoffBallotBy?.note,
    dates.dropoffBallotBy?.longNote
  ),
  ballotPostmarkBy: dateStringToMoment(
    notLocalized(dates.postmarkBallotBy?.electionDate)
  ),
  ballotPostmarkByNote: parseContentfulNote(
    dates.postmarkBallotBy?.note,
    dates.postmarkBallotBy?.longNote
  ),
  ballotReceiveBy: dateStringToMoment(
    notLocalized(dates.receiveBallotBy?.electionDate)
  ),
  ballotReceiveByNote: parseContentfulNote(
    dates.receiveBallotBy?.note,
    dates.receiveBallotBy?.longNote
  ),
  registerOnlineBy: dateStringToMoment(
    notLocalized(dates.registerOnlineBy?.electionDate)
  ),
  registerOnlineByNote: parseContentfulNote(
    dates.registerOnlineBy?.note,
    dates.registerOnlineBy?.longNote
  ),
  registerInPersonBy: dateStringToMoment(
    notLocalized(dates.registerInPersonBy?.electionDate)
  ),
  registerInPersonByNote: parseContentfulNote(
    dates.registerInPersonBy?.note,
    dates.registerInPersonBy?.longNote
  ),
  registerSameDayNote: parseContentfulNote(
    dates.registerSameDay?.note,
    dates.registerSameDay?.longNote
  ),
  registerReceiveBy: dateStringToMoment(
    notLocalized(dates.registerReceiveBy?.electionDate)
  ),
  registerReceiveByNote: parseContentfulNote(
    dates.registerReceiveBy?.note,
    dates.registerReceiveBy?.longNote
  ),
  registerPostmarkBy: dateStringToMoment(
    notLocalized(dates.registerPostmarkBy?.electionDate)
  ),
  registerPostmarkByNote: parseContentfulNote(
    dates.registerPostmarkBy?.note,
    dates.registerPostmarkBy?.longNote
  ),
  voteByMailNote: dates.voteByMailNote,
});

type ContentfulLinkedDateFields =
  TypeElectionDateWithAllLocalesResponse<ActiveLocale>['fields'];

/**
 * Internal type that’s used as an intermediary built from the list of
 * ElectionDate entries.
 */
type ContentfulDates = {
  earlyVotingStartBy?: ContentfulLinkedDateFields;
  electionDay?: ContentfulLinkedDateFields;
  electionDayStatewideHours?: ContentfulLinkedDateFields;
  inPersonAbsenteeStartBy?: ContentfulLinkedDateFields;
  requestBallotBy?: ContentfulLinkedDateFields;
  dropoffBallotBy?: ContentfulLinkedDateFields;
  postmarkBallotBy?: ContentfulLinkedDateFields;
  receiveBallotBy?: ContentfulLinkedDateFields;
  registerOnlineBy?: ContentfulLinkedDateFields;
  registerInPersonBy?: ContentfulLinkedDateFields;
  registerSameDay?: ContentfulLinkedDateFields;
  registerReceiveBy?: ContentfulLinkedDateFields;
  registerPostmarkBy?: ContentfulLinkedDateFields;
  voteByMailNote?: ActiveLocalizedRichTextObj;
};

type ContentfulDateMappingType = {
  [key in TypeElectionDateFields['dateType']['values']]: Exclude<
    keyof ContentfulDates,
    'voteByMailNote'
  >;
};

/**
 * Mapping of the `dateType` options seen in Contentful to the keys of our
 * {@link ContentfulDates} type.
 */
const DATE_TYPE_TO_DATES_KEY: ContentfulDateMappingType = {
  'Early Vote Start By': 'earlyVotingStartBy',
  'Election Day': 'electionDay',
  'Election Day Statewide Hours': 'electionDayStatewideHours',
  'In Person Absentee Start By': 'inPersonAbsenteeStartBy',
  'Request Ballot By': 'requestBallotBy',
  'Dropoff Ballot By': 'dropoffBallotBy',
  'Postmark Ballot By': 'postmarkBallotBy',
  'Receive Ballot By': 'receiveBallotBy',
  'Register Online By': 'registerOnlineBy',
  'Register In Person By': 'registerInPersonBy',
  'Register Same Day': 'registerSameDay',
  'Receive Register By': 'registerReceiveBy',
  'Register Postmark By': 'registerPostmarkBy',
};

/**
 * In Contentful, Important Dates and Deadlines are returned as an array of
 * objects.
 *
 * In IWV, the ImportantDates object represents a consolidation of the Important
 * Dates and Deadlines array. This method remaps the array of objects from
 * Contentful to the Important Dates object.
 */
export const mapDatesArrayToImportantDatesObject = (
  datesDeadlinesArray: Array<
    | contentful.Entry<
        TypeElectionDateSkeleton | TypeVoteByMailNoteSkeleton,
        'WITH_ALL_LOCALES',
        ActiveLocale
      >
    | contentful.UnresolvedLink<'Entry'>
  >
): ContentfulDates => {
  const out: ContentfulDates = {};

  for (const dateDeadline of datesDeadlinesArray) {
    if (!isEntry(dateDeadline)) {
      continue;
    }

    if (
      isTypeVoteByMailNote(dateDeadline) &&
      hasDefaultLocales(dateDeadline.fields.noteCopy)
    ) {
      out.voteByMailNote = localized(dateDeadline.fields.noteCopy);
    }

    if (isTypeElectionDate(dateDeadline)) {
      const contentfulDateKey =
        DATE_TYPE_TO_DATES_KEY[notLocalized(dateDeadline.fields.dateType)];

      // Safety existence check in case new options are added to `dateType` that IWV
      // doesn’t know about yet.
      if (contentfulDateKey) {
        out[contentfulDateKey] = dateDeadline.fields;
      }
    }
  }

  return out;
};

const parseLinkedElection = (
  linkedElection: TypeActiveElectionWithAllLocalesResponse<ActiveLocale>,
  todayDate: moment.Moment
): ElectionInfo | undefined => {
  const parsedDate = dateStringToMoment(
    notLocalized(linkedElection.fields.electionDate)
  );
  if (!parsedDate) {
    return undefined;
  }

  const electionInfo: ElectionInfo = {
    internalName: notLocalized(linkedElection.fields.internalName),
    electionType: notLocalized(linkedElection.fields.electionType),
    electionDay: parsedDate,
    earlyVoting: {
      allowed: notLocalized(linkedElection.fields.isEarlyVotingAllowed),
    },
  };

  if (
    linkedElection.fields.earlyVotingStartDate &&
    linkedElection.fields.earlyVotingEndDate
  ) {
    electionInfo.earlyVoting.startDate = dateStringToMoment(
      notLocalized(linkedElection.fields.earlyVotingStartDate)
    );
    electionInfo.earlyVoting.endDate = dateStringToMoment(
      notLocalized(linkedElection.fields.earlyVotingEndDate)
    );
  }

  if (moment(electionInfo.electionDay).isSameOrAfter(todayDate, 'day')) {
    return electionInfo;
  } else {
    return undefined;
  }
};

export class ContentfulService {
  private readonly previewUrl: string;

  private readonly displayUrl: string;

  private readonly previewToken: string;

  private readonly displayToken: string;

  private readonly spaceId: string;

  constructor(
    previewUrl = import.meta.env.VITE_CONTENTFUL_PREVIEW_HOST as string,
    displayUrl = import.meta.env.VITE_CONTENTFUL_DISPLAY_HOST as string,
    previewToken = import.meta.env
      .VITE_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string,
    displayToken = import.meta.env
      .VITE_CONTENTFUL_DISPLAY_ACCESS_TOKEN as string,
    spaceId = import.meta.env.VITE_CONTENTFUL_SPACE_ID as string
  ) {
    this.previewUrl = previewUrl;
    this.displayUrl = displayUrl;
    this.previewToken = previewToken;
    this.displayToken = displayToken;
    this.spaceId = spaceId;
  }

  stateDeadlinesToImportantDate(
    entry: ContentfulJurisdictionConfig
  ): ImportantDates {
    const datesAndVbmNoteArray =
      notLocalized(entry.importantDatesAndDeadlines) ?? [];
    const earlyVoteBoolean = notLocalized(
      entry.earlyVotingStartIsSameStatewide
    );
    const datesObject =
      mapDatesArrayToImportantDatesObject(datesAndVbmNoteArray);
    return parseElectionDates(datesObject, earlyVoteBoolean);
  }

  private territoryDeadlinesToImportantDate(
    entry: ContentfulTerritoryConfig
  ): ImportantDates {
    const datesArray = notLocalized(entry.importantDatesAndDeadlines) ?? [];
    // Early Vote Boolean is hard-coded to false, because
    // VEP text 'Starts Statewide' does not apply to territories
    const earlyVoteBoolean = false;
    const datesObject = mapDatesArrayToImportantDatesObject(datesArray);
    return parseElectionDates(datesObject, earlyVoteBoolean);
  }

  electionToElectionInfo(
    entry: ContentfulJurisdictionConfig,
    today: moment.Moment
  ): Option<ElectionInfo> {
    const election = notLocalized(entry.activeElection);
    if (!isEntry(election)) {
      return undefined;
    }
    return parseLinkedElection(election, today);
  }

  extractAlert(entry: ContentfulJurisdictionConfig): Array<LinkedAlertFields> {
    const alert = notLocalized(entry.jurisdictionAlertsConfig);
    if (!isEntry(alert)) {
      return [];
    }
    return [alert.fields];
  }

  private getLocateMessage(
    entry: ContentfulJurisdictionConfig,
    todayDate: moment.Moment
  ): JurisdictionLocateMessage | null {
    const lookupConfigEntry = notLocalized(entry.pollingLocationLookupConfig);

    if (!isEntry(lookupConfigEntry)) {
      return null;
    }

    const lookupConfig = lookupConfigEntry.fields;

    if (
      lookupConfig.locateErrorStartDate &&
      lookupConfig.locateErrorEndDate &&
      lookupConfig.locateErrorHeadlineCopy &&
      lookupConfig.locateErrorMessageCopy
    ) {
      // we only want to return active locate error messages
      const errorStart = dateStringToMoment(
        notLocalized(lookupConfig.locateErrorStartDate)
      );
      const errorEnd = dateStringToMoment(
        notLocalized(lookupConfig.locateErrorEndDate)
      );
      if (
        moment(errorStart).isSameOrBefore(todayDate, 'day') &&
        moment(errorEnd).isSameOrAfter(todayDate, 'day')
      ) {
        return {
          headline: localized(lookupConfig.locateErrorHeadlineCopy),
          errorMessage: localized(lookupConfig.locateErrorMessageCopy),
        };
      }
    }
    /* 
      if the entry exists, but:
      - there is no locate error message
      - or the locate error message has expired
      we return null
    */
    return null;
  }

  private getLocationResultsInformationalNotes(
    entry: ContentfulJurisdictionConfig
  ): JurisdictionPollingLookupConfig['informationalNotes'] {
    const pollingLocationLookupConfig = notLocalized(
      entry.pollingLocationLookupConfig
    );

    if (!isEntry(pollingLocationLookupConfig)) {
      return null;
    }

    const {
      earlyVoteInformationalNote,
      dropoffInformationalNote,
      electionDayInformationalNote,
    } = pollingLocationLookupConfig.fields;
    // if all of the fields are empty
    // return null
    if (
      !earlyVoteInformationalNote &&
      !dropoffInformationalNote &&
      !electionDayInformationalNote
    ) {
      return null;
    }
    return {
      dropoffLocations: hasDefaultLocales(dropoffInformationalNote)
        ? localized(dropoffInformationalNote)
        : null,
      earlyVoteLocations: hasDefaultLocales(earlyVoteInformationalNote)
        ? localized(earlyVoteInformationalNote)
        : null,
      electionDayLocations: hasDefaultLocales(electionDayInformationalNote)
        ? localized(electionDayInformationalNote)
        : null,
    };
  }

  extractPollingLocationLookupConfig(
    entry: ContentfulJurisdictionConfig,
    today: moment.Moment,
    election: Option<ElectionInfo>
  ): JurisdictionPollingLookupConfig {
    const pollingConfigEntry = notLocalized(entry.pollingLocationLookupConfig);
    if (!isEntry(pollingConfigEntry)) {
      return {
        displayVotingLocationLookup: false,
        earlyAbsenteeExcuse: null,
        lookupExperience: 'none',
        informationalNotes: null,
        sosLookupUrl: null,
        locateMessage: null,
      };
    }
    const pollingConfig = pollingConfigEntry.fields;
    const locateMessage = this.getLocateMessage(entry, today);
    const informationalNotes = this.getLocationResultsInformationalNotes(entry);
    // Contentful has a `displayVotingLocationLookup` boolean
    // meant to toggle on/off the "Find out where to vote..." button
    // For use in IWV, we do care about this boolean,
    // but we also need to take into account whether
    // there is an active election for the jurisdiction.
    // An active election is used by /locate
    // to filter VIS' polling location response
    // TLDR: Even if the `displayVotingLocationLookup` boolean is set to `true`,
    // without an active election, the location finder will not display
    const lookupDisplayResult =
      notLocalized(pollingConfig.displayVotingLocationLookup) && !!election;
    const {
      displayEarlyAbsenteeExcuse,
      earlyAbsenteeExcuseCopy,
      sosLocationLookupUrl,
    } = pollingConfig;
    return {
      displayVotingLocationLookup: lookupDisplayResult,
      lookupExperience: lookupDisplayResult
        ? notLocalized(pollingConfig.locationLookupExperience)
        : 'none',
      earlyAbsenteeExcuse: notLocalized(displayEarlyAbsenteeExcuse)
        ? {
            copy: hasDefaultLocales(earlyAbsenteeExcuseCopy)
              ? localized(earlyAbsenteeExcuseCopy)
              : null,
          }
        : null,
      sosLookupUrl: hasEnglishLocale(sosLocationLookupUrl)
        ? localized(sosLocationLookupUrl)
        : null,
      locateMessage,
      informationalNotes,
    };
  }

  private extractRegistrationConfig(
    entry: ContentfulJurisdictionConfig
  ): Option<JurisdictionRegistrationConfig> {
    const voterRegConfigEntry = notLocalized(entry.voterRegConfig);
    if (!isEntry(voterRegConfigEntry)) {
      return undefined;
    }
    let voterRegConfigToReturn: JurisdictionRegistrationConfig | undefined =
      undefined;
    Sentry.withScope(function (scope) {
      scope.setContext('voterRegConfig', entry);
      try {
        const {
          displayRegistrationLookup,
          onlineRegistrationEnabled,
          onlineRegistrationDeadlineIsDisplayed,
          mailRegistrationEnabled,
          mailRegistrationDeadlineIsDisplayed,
          inPersonRegistrationEnabled,
          inPersonRegistrationDeadlineIsDisplayed,
          onlineRegistrationHeading,
          onlineRegistrationUrl,
          onlineRegistrationButtonText,
          onlineRegistrationCopy,
          alternateRegistrationUrl,
          alternateRegistrationButtonText,
          mailRegistrationHeading,
          mailRegistrationUrl,
          mailRegistrationButtonText,
          mailRegistrationCopy,
          showRegistrationSteps,
          inPersonRegistrationHeading,
          inPersonRegistrationUrl,
          inPersonRegistrationButtonText,
          inPersonRegistrationCopy,
          sameDayRegistrationEnabled,
          sameDayRegistrationHeading,
          sameDayRegistrationCopy,
        } = voterRegConfigEntry.fields;

        voterRegConfigToReturn = {
          registrationFlowEnabled:
            notLocalized(onlineRegistrationEnabled) ||
            notLocalized(mailRegistrationEnabled) ||
            notLocalized(inPersonRegistrationEnabled) ||
            notLocalized(sameDayRegistrationEnabled),
          displayRegistrationLookup: notLocalized(displayRegistrationLookup),
          sameDay: {
            enabled: notLocalized(sameDayRegistrationEnabled),
            sameDayHeading: localized(sameDayRegistrationHeading),
            copy: localized(sameDayRegistrationCopy),
          },
          online: {
            enabled: notLocalized(onlineRegistrationEnabled),
            displayDeadline: notLocalized(
              onlineRegistrationDeadlineIsDisplayed
            ),
            onlineHeading: localized(onlineRegistrationHeading),
            copy: localized(onlineRegistrationCopy),
            onlineUrl: localized(onlineRegistrationUrl),
            onlineButtonText: localized(onlineRegistrationButtonText),
            alternateUrl: localized(alternateRegistrationUrl),
            alternateButtonText: localized(alternateRegistrationButtonText),
          },
          mail: {
            enabled: notLocalized(mailRegistrationEnabled),
            displayDeadline: notLocalized(mailRegistrationDeadlineIsDisplayed),
            mailHeading: localized(mailRegistrationHeading),
            showRegistrationSteps: notLocalized(showRegistrationSteps),
            copy: localized(mailRegistrationCopy),
            mailUrl: localized(mailRegistrationUrl),
            mailButtonText: localized(mailRegistrationButtonText),
          },
          inPerson: {
            enabled: notLocalized(inPersonRegistrationEnabled),
            displayDeadline: notLocalized(
              inPersonRegistrationDeadlineIsDisplayed
            ),
            inPersonHeading: localized(inPersonRegistrationHeading),
            copy: localized(inPersonRegistrationCopy),
            inPersonUrl: localized(inPersonRegistrationUrl),
            inPersonButtonText: localized(inPersonRegistrationButtonText),
          },
        };
      } catch (err) {
        Sentry.captureException(err);
      }
    });
    return voterRegConfigToReturn;
  }

  private extractStateVepConfig(
    entry: ContentfulJurisdictionConfig
  ): JurisdictionVepConfig {
    const dates = this.stateDeadlinesToImportantDate(entry);
    const vepConfigEntry = notLocalized(entry.vepConfig);
    if (!isEntry(vepConfigEntry)) {
      return FALLBACK_VEP_CONFIG_OBJECT;
    }
    const vepConfigObject = vepConfigEntry.fields;
    const datesBoolean = notLocalized(entry.displayDatesDeadlines);
    // 'enableCustomSectionOnVep' is a new field
    // this gracefully handles jurisdiction/vep configs without
    // new ev section fields
    //
    // TODO(fiona): Can we remove this now?
    const enableCustomSectionOnVep =
      'enableCustomSectionOnVep' in vepConfigObject
        ? notLocalized(vepConfigObject.enableCustomSectionOnVep)
        : false;
    return {
      jurisdictionCode: notLocalized(entry.stateCode),
      vepEnabled: notLocalized(vepConfigObject.vepEnabled),
      locationLookup: {
        displayOnVep: notLocalized(vepConfigObject.includeLocateOnVep),
        displayInDatesDeadlines: notLocalized(
          vepConfigObject.includeLocateInVepDates
        ),
      },
      importantDatesAndDeadlines: datesBoolean ? dates : null,
      idRequirements: notLocalized(vepConfigObject.displayIdCopy)
        ? {
            copy: localized(vepConfigObject.idRequirementsCopy),
          }
        : null,
      registrationRequirements: notLocalized(
        vepConfigObject.displayRegistrationCopy
      )
        ? {
            copy: localized(vepConfigObject.registrationRequirementsCopy),
          }
        : null,
      howToCompleteBallot:
        notLocalized(vepConfigObject.displayHowToCompleteBallotCopy) &&
        hasDefaultLocales(vepConfigObject.howToCompleteBallotCopy)
          ? {
              copy: localized(vepConfigObject.howToCompleteBallotCopy),
            }
          : null,
      customSection:
        enableCustomSectionOnVep &&
        hasDefaultLocales(vepConfigObject.customSectionHeading) &&
        hasDefaultLocales(vepConfigObject.customSectionCopy)
          ? {
              heading: localized(vepConfigObject.customSectionHeading),
              copy: localized(vepConfigObject.customSectionCopy),
            }
          : null,
    };
  }

  private extractTerritoryVepConfig(
    entry: ContentfulTerritoryConfig
  ): JurisdictionVepConfig {
    const dates = this.territoryDeadlinesToImportantDate(entry);
    const vepConfigEntry = notLocalized(entry.vepConfig);

    if (!isEntry(vepConfigEntry)) {
      return FALLBACK_VEP_CONFIG_OBJECT;
    }

    const vepConfigObject = vepConfigEntry.fields;
    const datesBoolean = notLocalized(entry.displayDatesDeadlines);
    return {
      jurisdictionCode: notLocalized(entry.territoryCode),
      vepEnabled: notLocalized(vepConfigObject.vepEnabled),
      locationLookup: {
        displayOnVep: false,
        displayInDatesDeadlines: false,
      },
      importantDatesAndDeadlines: datesBoolean ? dates : null,
      idRequirements:
        notLocalized(vepConfigObject.displayIdCopy) &&
        hasDefaultLocales(vepConfigObject.idRequirementsCopy)
          ? {
              copy: localized(vepConfigObject.idRequirementsCopy),
            }
          : null,
      registrationRequirements:
        notLocalized(vepConfigObject.displayRegistrationCopy) &&
        hasDefaultLocales(vepConfigObject.registrationRequirementsCopy)
          ? {
              copy: localized(vepConfigObject.registrationRequirementsCopy),
            }
          : null,
      howToCompleteBallot: null,
      customSection: null,
    };
  }

  private extractStateVepConfigBooleans(
    entry: ContentfulJurisdictionConfig
  ): VepBooleans {
    const vepConfigEntry = notLocalized(entry.vepConfig);
    if (!isEntry(vepConfigEntry)) {
      return {
        displayDatesDeadlines: false,
        earlyVotingStartIsSameStatewide: false,
        includeLocateOnVep: false,
        includeLocateInVepDates: false,
        displayIdCopy: false,
        displayRegistrationCopy: false,
        displayHowToCompleteBallotCopy: false,
        enableCustomSection: false,
      };
    }
    const vepConfigObject = vepConfigEntry.fields;
    const datesBoolean = notLocalized(entry.displayDatesDeadlines);
    // 'enableCustomSectionOnVep' is a new field
    // this gracefully handles jurisdiction/vep configs without
    // new ev section fields
    //
    // TODO(fiona): Can we remove this?
    const enableCustomSectionOnVep =
      'enableCustomSectionOnVep' in vepConfigObject
        ? notLocalized(vepConfigObject.enableCustomSectionOnVep)
        : false;
    return {
      displayDatesDeadlines: datesBoolean,
      earlyVotingStartIsSameStatewide: notLocalized(
        entry.earlyVotingStartIsSameStatewide
      ),
      includeLocateOnVep: notLocalized(vepConfigObject.includeLocateOnVep),
      includeLocateInVepDates: notLocalized(
        vepConfigObject.includeLocateInVepDates
      ),
      displayIdCopy: notLocalized(vepConfigObject.displayIdCopy),
      displayRegistrationCopy: notLocalized(
        vepConfigObject.displayRegistrationCopy
      ),
      displayHowToCompleteBallotCopy: notLocalized(
        vepConfigObject.displayHowToCompleteBallotCopy
      ),
      enableCustomSection: enableCustomSectionOnVep,
    };
  }

  private extractBallotRequestConfig(
    entry: ContentfulJurisdictionConfig
  ): Option<JurisdictionBallotRequestConfig> {
    const ballotRequestConfigEntry = notLocalized(entry.ballotRequestConfig);
    if (!isEntry(ballotRequestConfigEntry)) {
      return undefined;
    }
    const ballotRequestConfigObj = ballotRequestConfigEntry.fields;
    const ballotRequestExperience = notLocalized(
      ballotRequestConfigObj.ballotRequestFlowEnabled
    )
      ? notLocalized(ballotRequestConfigObj.ballotRequestExperience)
      : 'none';
    return {
      hasUniversalVBM: notLocalized(ballotRequestConfigObj.hasUniversalVbm),
      ballotRequestFlowEnabled: notLocalized(
        ballotRequestConfigObj.ballotRequestFlowEnabled
      ),
      ballotRequestExperience: ballotRequestExperience,
      sosBallotRequestUrl: localized(
        ballotRequestConfigObj.sosBallotRequestUrl
      ),
      ballotRequestButtonText: hasEnglishLocale(
        ballotRequestConfigObj.ballotRequestButtonText
      )
        ? localized(ballotRequestConfigObj.ballotRequestButtonText)
        : undefined,
    };
  }

  private extractHotlineFooterOverrideConfig(
    entry: ContentfulJurisdictionConfig
  ): Option<JurisdictionHotlineFooterOverride> {
    const hotlineFooterOverrideConfigEntry = notLocalized(
      entry.hotlineAndFooterOverrideConfig
    );

    if (!isEntry(hotlineFooterOverrideConfigEntry)) {
      return undefined;
    }

    const hotlineFooterOverrideConfigObj =
      hotlineFooterOverrideConfigEntry.fields;

    return {
      hotlineOverrideCopy: {
        copy: localized(hotlineFooterOverrideConfigObj.hotlineOverrideCopy),
      },
      footerOverrideCopy: {
        copy: localized(hotlineFooterOverrideConfigObj.footerOverrideCopy),
      },
      stateCode: notLocalized(hotlineFooterOverrideConfigObj.stateCode),
    };
  }

  private extractCustomLandingPageButtonsConfig(
    entry: ContentfulJurisdictionConfig
  ): Option<JurisdictionCustomLandingPageButtons> {
    const customLandingPageButtonsEntry = notLocalized(
      entry.customLandingPageButtonsConfig
    );
    if (!isEntry(customLandingPageButtonsEntry)) {
      return undefined;
    }
    const customLandingPageButtonsConfigObj =
      customLandingPageButtonsEntry.fields;
    return {
      customButtonAUrl: hasEnglishLocale(
        customLandingPageButtonsConfigObj.customButtonAUrl
      )
        ? localized(customLandingPageButtonsConfigObj.customButtonAUrl)
        : undefined,
      customButtonAText: hasEnglishLocale(
        customLandingPageButtonsConfigObj.customButtonAText
      )
        ? localized(customLandingPageButtonsConfigObj.customButtonAText)
        : undefined,
      customButtonBUrl: hasEnglishLocale(
        customLandingPageButtonsConfigObj.customButtonBUrl
      )
        ? localized(customLandingPageButtonsConfigObj.customButtonBUrl)
        : undefined,
      customButtonBText: hasEnglishLocale(
        customLandingPageButtonsConfigObj.customButtonBText
      )
        ? localized(customLandingPageButtonsConfigObj.customButtonBText)
        : undefined,
    };
  }

  private extractCustomPages(
    entry: ContentfulJurisdictionConfig
  ): JurisdictionCustomPage[] {
    return (
      (notLocalized(entry.customPages) ?? [])
        .filter(isEntry)
        // Type casting filter because the schema just links to generic entries,
        // not the custom page entry specifically.
        .filter(isTypeCustomPage)
        .filter((p) => notLocalized(p.fields.pageEnabled))
        .map<JurisdictionCustomPage>((p) => ({
          slug: notLocalized(p.fields.slug),
          title: localized(p.fields.pageTitle),
          content: localized(p.fields.pageContent),
        }))
    );
  }

  /**
   * Maps the language names from Contentful
   * in languageSupport field to their corresponding locales.
   * Falls back to {@link DEFAULT_LOCALES} if no entry is provided,
   * or if the field is not configured on the Contentful entry.
   *
   * 'languageSupport':
   *   - used to render <LocaleSelector/> in Site Language Menu
   *   - finalLocaleSupport for 'languageSupport' MUST include both 'en' and 'es'
   *     - if 'English' and 'Spanish' are not included in the languageSupport field,
   *       extractSupportedLocalesConfig() explicitly adds them
   */
  extractSupportedLocalesConfig(
    entry: ContentfulJurisdictionConfig
  ): JurisdictionLocaleSupport {
    const configuredLanguages = notLocalized(entry.languageSupport);

    if (!configuredLanguages) {
      return DEFAULT_LOCALES;
    }

    const finalLocaleSupport =
      this.extractValidLocalesFromConfiguredLanguages(configuredLanguages);

    // ensure default locales are included in supportedLocales
    if (!finalLocaleSupport.includes(DefaultLanguageToLocale['English'])) {
      finalLocaleSupport.push('en');
    }
    if (!finalLocaleSupport.includes(DefaultLanguageToLocale['Spanish'])) {
      finalLocaleSupport.push('es');
    }
    // sort locales alphabetically
    finalLocaleSupport.sort();

    return finalLocaleSupport;
  }

  /**
   * Maps the language names from Contentful
   * in priorityLanguages field to their corresponding locales.
   * Falls back to {@link DEFAULT_LOCALES} if no entry is provided,
   * or if the field is not configured on the Contentful entry.
   * Also falls back to DEFAULT_LOCALES if Contentful's priority language options
   * are ahead of IWV {@link ActiveLanguage} definitions.
   *
   * 'priorityLanguages':
   *   - used for mobile layouts
   *   - maximum of two (logic resides in Contentful)
   *   - determines which <LocaleSelector/> has/have prioritized display
   *      - non-'priorityLanguages' within 'languageSupport' are displayed under a 'More ▼' dropdown
   */
  extractPriorityLocalesConfig(
    entry: ContentfulJurisdictionConfig
  ): JurisdictionLocaleSupport {
    const configuredLanguages = notLocalized(entry.priorityLanguages);
    if (!configuredLanguages) {
      return DEFAULT_LOCALES;
    }

    const finalLocaleSupport =
      this.extractValidLocalesFromConfiguredLanguages(configuredLanguages);
    // if configured priorityLanguages are ahead of IWV's definitions
    // fallback to default locales
    if (finalLocaleSupport.length === 0) {
      return DEFAULT_LOCALES;
    }
    // sort locales alphabetically
    finalLocaleSupport.sort();

    return finalLocaleSupport;
  }

  /**
   * Validates language names from Contentful against IWV's definitions
   * of {@link ActiveLanguage}, in case Contentful's options for
   * languageSupport or priorityLanguages are ahead of existing IWV definitions.
   */
  extractValidLocalesFromConfiguredLanguages(
    configuredLanguages: string[]
  ): ActiveLocale[] {
    const validLocaleSupport: ActiveLocale[] = configuredLanguages
      .filter(isActiveLanguage)
      .map((activeLanguage) => ActiveLanguageToLocale[activeLanguage]);
    return [...validLocaleSupport];
  }

  private extractNationalBooleanConfig(
    nationalEntries: ContentfulJurisdictionConfig[],
    today: moment.Moment
  ) {
    const nationalBooleanConfig = {} as NationalBooleanConfig;
    nationalEntries.forEach((entry) => {
      const stateCode = notLocalized(entry.stateCode);
      nationalBooleanConfig[stateCode] = this.extractJurisdictionBooleanConfig(
        entry,
        today
      );
    });
    return nationalBooleanConfig;
  }

  private extractNationalLocalesConfig(
    nationalEntries: ContentfulJurisdictionConfig[]
  ) {
    const nationalLocalesConfig = {} as NationalLocalesConfig;
    nationalEntries.forEach((entry) => {
      const stateCode = entry.stateCode.en as State;
      nationalLocalesConfig[stateCode] = {
        supportedLocales: this.extractSupportedLocalesConfig(entry),
        priorityLocales: this.extractPriorityLocalesConfig(entry),
      };
    });
    return nationalLocalesConfig;
  }

  private extractJurisdictionBooleanConfig(
    entry: ContentfulJurisdictionConfig,
    today: moment.Moment
  ): JurisdictionBooleanConfig {
    const election = this.electionToElectionInfo(entry, today);
    const alert = this.extractAlert(entry);
    const pollingLocationLookupConfig = this.extractPollingLocationLookupConfig(
      entry,
      today,
      election
    );
    const registrationConfig = this.extractRegistrationConfig(entry);
    const vepConfig = notLocalized(entry.vepConfig);
    const voterEdPageBooleans = this.extractStateVepConfigBooleans(entry);
    const ballotRequestConfig = this.extractBallotRequestConfig(entry);
    return {
      landingPage: {
        vepEnabled: isEntry(vepConfig)
          ? notLocalized(vepConfig.fields.vepEnabled)
          : false,
        displayVotingLocationLookup:
          pollingLocationLookupConfig.displayVotingLocationLookup,
        displayRegistrationLookup:
          !!registrationConfig?.displayRegistrationLookup,
        ballotRequestFlowEnabled:
          !!ballotRequestConfig?.ballotRequestFlowEnabled,
        hasActiveElection: !!election,
        hasActiveAlerts: !!alert,
      },
      vep: {
        ...voterEdPageBooleans,
      },
      locate: {
        displayVotingLocationLookup:
          pollingLocationLookupConfig.displayVotingLocationLookup,
        displayEarlyAbsenteeExcuse:
          !!pollingLocationLookupConfig.earlyAbsenteeExcuse &&
          !!pollingLocationLookupConfig.earlyAbsenteeExcuse.copy,
        isEarlyVotingAllowed: !!election?.earlyVoting.allowed,
        informationalNotes: {
          dropoffLocations:
            !!pollingLocationLookupConfig.informationalNotes?.dropoffLocations,
          earlyVoteLocations:
            !!pollingLocationLookupConfig.informationalNotes
              ?.earlyVoteLocations,
          electionDayLocations:
            !!pollingLocationLookupConfig.informationalNotes
              ?.electionDayLocations,
        },
        hasActiveElection: !!election,
        hasLocateOverrideMessage: !!pollingLocationLookupConfig.locateMessage,
        lookupExperience: pollingLocationLookupConfig.lookupExperience,
        sosLookupUrl: pollingLocationLookupConfig.sosLookupUrl,
      },
      ballotRequest: {
        hasUniversalVbm: !!ballotRequestConfig?.hasUniversalVBM,
        ballotRequestFlowEnabled:
          !!ballotRequestConfig?.ballotRequestFlowEnabled,
      },
      register: {
        registrationFlowEnabled: !!registrationConfig?.registrationFlowEnabled,
        sameDayRegistrationEnabled: !!registrationConfig?.sameDay.enabled,
        onlineRegistrationEnabled: !!registrationConfig?.online.enabled,
        onlineRegistrationDeadlineIsDisplayed:
          !!registrationConfig?.online.displayDeadline,
        mailRegistrationEnabled: !!registrationConfig?.mail.enabled,
        mailRegistrationDeadlineIsDisplayed:
          !!registrationConfig?.mail.displayDeadline,
        showRegistrationSteps: !!registrationConfig?.mail.showRegistrationSteps,
        inPersonRegistrationEnabled: !!registrationConfig?.inPerson.enabled,
        inPersonRegistrationDeadlineIsDisplayed:
          !!registrationConfig?.inPerson.displayDeadline,
      },
    };
  }

  private createContentfulClient(isPreview: boolean) {
    const configuration = this.createContentfulConfig(isPreview);
    return contentful.createClient(configuration);
  }

  createContentfulConfig(isPreview: boolean) {
    const token = isPreview ? this.previewToken : this.displayToken;
    const hostUrl = isPreview ? this.previewUrl : this.displayUrl;
    return {
      accessToken: token,
      host: hostUrl,
      space: this.spaceId,
    };
  }

  async fetchJurisdictionConfig(
    stateCode: Option<Jurisdiction>,
    isPreview: boolean,
    useTestConfig: boolean,
    today: moment.Moment
  ): Promise<Option<JurisdictionConfig>> {
    /*
      Example documentation for requesting a single entry:
      Content Preview API: https://www.contentful.com/developers/docs/references/content-preview-api/#/reference/entries/entry/get-a-single-entry/console/js
      Content Delivery API: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/entries/entry/get-a-single-entry/console/js
      Adding search parameters to a query:
      Content Preview API: https://www.contentful.com/developers/docs/references/content-preview-api/#/reference/search-parameters
      Content Delivery API: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters
    */

    if (stateCode === undefined) {
      return undefined;
    }

    const client = this.createContentfulClient(isPreview);
    const entryId = useTestConfig
      ? TestingJurisdictionConfigEntries[stateCode as State]
      : ProductionJurisdictionConfigEntries[stateCode as State];

    /* 
      Instead of making a fetch request, we use Contentful's client to handle
      getting the jurisdiction config object.
      Using client.getEntry() targets a specific entry id,
      while client.getEntries() can broadly target a specific content type.
      getEntries() does accept query params, but in testing, it
      was not reliably filtering on stateCode.
      `include` and client.getEntry():
      `include` refers to linked references, specifically how many levels down
      to include in the response.
      
      New in contentful@10.0.0 : locale handling update
        Contentful introduced "client chaining methods"
        We call client.withAllLocales.getEntry() to request en/es
        localized content for a jurisdiction config object.
    */
    const entryResponse = await client.withAllLocales.getEntry<
      TypeJurisdictionConfigSkeleton,
      ActiveLocale
    >(entryId, {
      include: 3,
    });
    const entry = entryResponse.fields;
    const election = this.electionToElectionInfo(entry, today);
    const alert = this.extractAlert(entry);
    const pollingLocationLookupConfig = this.extractPollingLocationLookupConfig(
      entry,
      today,
      election
    );
    const registrationConfig = this.extractRegistrationConfig(entry);
    const voterEdPageConfig = this.extractStateVepConfig(entry);
    const ballotRequestConfig = this.extractBallotRequestConfig(entry);
    const jurisdictionLocaleConfig = this.extractSupportedLocalesConfig(entry);
    const priorityLocaleConfig = this.extractPriorityLocalesConfig(entry);
    const hotlineFooterOverride =
      this.extractHotlineFooterOverrideConfig(entry);
    const customLandingPageButtons =
      this.extractCustomLandingPageButtonsConfig(entry);
    const customPages = this.extractCustomPages(entry);

    return {
      stateName: notLocalized(entry.stateName),
      stateCode: notLocalized(entry.stateCode),
      voterHotline: notLocalized(entry.voterHotline),
      supportedLocales: jurisdictionLocaleConfig,
      priorityLocales: priorityLocaleConfig,
      jurisdictionAlert: alert,
      electionInfo: election,
      pollingLocationLookupConfig: pollingLocationLookupConfig,
      registrationConfig: registrationConfig,
      vepConfig: voterEdPageConfig,
      ballotRequestConfig: ballotRequestConfig,
      updatedAt: dateStringToMoment(entryResponse.sys.updatedAt).calendar(),
      hotlineFooterOverride: hotlineFooterOverride,
      customLandingPageButtons: customLandingPageButtons,
      customPages,
    };
  }

  async fetchTerritoryConfig(
    territoryCode: Option<Jurisdiction>,
    isPreview: boolean,
    useTestConfig: boolean
  ): Promise<Option<TerritoryConfig>> {
    if (territoryCode === undefined) {
      return undefined;
    }

    const client = this.createContentfulClient(isPreview);
    const entryId = useTestConfig
      ? TestingTerritoryConfigEntries[territoryCode as Territory]
      : ProductionTerritoryConfigEntries[territoryCode as Territory];

    const entryResponse = await client.withAllLocales.getEntry<
      TypeTerritoryConfigSkeleton,
      ActiveLocale
    >(entryId, {
      include: 3,
    });
    const entry = entryResponse.fields;

    const voterEdPageConfig = this.extractTerritoryVepConfig(entry);

    return {
      territoryName: notLocalized(entry.territoryName),
      territoryCode: notLocalized(entry.territoryCode),
      vepConfig: voterEdPageConfig,
    };
  }

  async fetchSitewideContent(
    isPreview: boolean
  ): Promise<Option<SitewideAlertsObject>> {
    /*
      This request targets the Sitewide Alerts content model
      (which is not specific to a jurisdiction, but the entire site)
      --the request query does not need to include a state code.
    */
    const client = this.createContentfulClient(isPreview);
    const contentId = 'sitewideAlerts';

    const entryResponse = await client.withAllLocales.getEntries<
      TypeSitewideAlertsSkeleton,
      ActiveLocale
    >({
      content_type: contentId,
      include: 3,
      limit: 1,
    });
    let alerts: LinkedAlertFields[] = [];
    if (entryResponse.items.length > 0) {
      const linkedAlert = entryResponse.items[0]!.fields;
      const linkedAlertEntry = notLocalized(linkedAlert.alerts);
      if (isEntry(linkedAlertEntry)) {
        alerts = [linkedAlertEntry.fields];
      }
    }
    return { sitewideAlerts: alerts };
  }

  async fetchJurisdictionBooleanConfig(
    stateCode: Jurisdiction,
    isPreview: boolean,
    today: moment.Moment
  ): Promise<JurisdictionBooleanConfig> {
    const client = this.createContentfulClient(isPreview);
    const entryId = ProductionJurisdictionConfigEntries[stateCode as State];
    const entryResponse = await client.withAllLocales.getEntry<
      TypeJurisdictionConfigSkeleton,
      ActiveLocale
    >(entryId, {
      include: 3,
    });
    const entry = entryResponse.fields;
    const jurisdictionBooleans = this.extractJurisdictionBooleanConfig(
      entry,
      today
    );
    return jurisdictionBooleans;
  }

  async fetchNationalConfigs(
    isPreview: boolean,
    today: moment.Moment
  ): Promise<[NationalBooleanConfig, NationalLocalesConfig]> {
    const client = this.createContentfulClient(isPreview);
    const productionEntryIds = Object.values(
      ProductionJurisdictionConfigEntries
    );
    const nationalResponse = await client.withAllLocales.getEntries<
      TypeJurisdictionConfigSkeleton,
      ActiveLocale
    >({
      content_type: 'jurisdictionConfig',
      'sys.id[in]': productionEntryIds,
      include: 3,
    });
    const nationalEntries = nationalResponse.items;
    const nationalEntryFields = nationalEntries.map((entry) => entry.fields);
    const nationalBooleans = this.extractNationalBooleanConfig(
      nationalEntryFields,
      today
    );
    const nationalLocales =
      this.extractNationalLocalesConfig(nationalEntryFields);
    return [nationalBooleans, nationalLocales];
  }
}
