import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import * as React from 'react';

import { useLocation } from 'react-router';

import type {
  JurisdictionConfig,
  LinkedAlertFields,
} from '@dnc/baseline/contentful/types';
import type { ActiveLocalizedString } from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';
import {
  BackupAlertBanner,
  type BackupAlert,
} from '@dnc/shared-components/BackupAlertBanner';
import { RichTextAlertBanner } from '@dnc/shared-components/RichTextAlertBanner';

import { type Services } from '../../services/services';
import { UrlHelper } from '../../services/url-helper';

export type Alerts = {
  activeSitewideAlert: LinkedAlertFields | undefined;
  activeJurisdictionAlert: LinkedAlertFields | undefined;
  activeBackupAlertMessage: ActiveLocalizedString | undefined;
};

/**
 * Filters a list of alerts ({@link LinkedAlertFields}) down to the first one
 * whose date range includes `today` (inclusive).
 */
export function findActiveAlert(
  submittedAlerts: Array<LinkedAlertFields>,
  today: moment.Moment
) {
  return submittedAlerts.find(
    (alert) =>
      today.isSameOrAfter(alert.startDate.en, 'day') &&
      today.isSameOrBefore(alert.endDate.en, 'day')
  );
}

/**
 * Returns the message from the {@link BackupAlert} if `today` is within its
 * date range (inclusive).
 */
export function findActiveBackupAlert(
  backupAlert: BackupAlert,
  today: moment.Moment
) {
  // This method takes a backup alert's start and end dates
  // into consideration, so even hard-coded
  // alerts won't require special efforts to
  // take down when they should be 'deactivated'.
  if (
    today.isSameOrAfter(backupAlert.startDate, 'day') &&
    today.isSameOrBefore(backupAlert.endDate, 'day')
  ) {
    return backupAlert.alertMessage;
  } else {
    return undefined;
  }
}

/**
 * Component for rendering the UI for any site-wide or state-wide banner
 * messages.
 *
 * Get the data for this component from {@link useAlerts}.
 */
export default function SiteBanners({
  alerts,
}: {
  alerts: Alerts;
}): React.ReactNode {
  return (
    <>
      {alerts.activeSitewideAlert && (
        <RichTextAlertBanner
          alert={alerts.activeSitewideAlert}
          className="alert-bar"
        />
      )}

      {alerts.activeJurisdictionAlert && (
        <RichTextAlertBanner
          alert={alerts.activeJurisdictionAlert}
          className="alert-bar"
        />
      )}

      {alerts.activeBackupAlertMessage && (
        <BackupAlertBanner
          alert={alerts.activeBackupAlertMessage}
          className="alert-bar"
        />
      )}
    </>
  );
}

/**
 * Hook to gather up the alert data for {@link SiteBanners}. For sitewide
 * alerts, we grab them via a separate `useQuery` so that we don’t delay initial
 * page rendering to show them.
 *
 * Split out from {@link SiteBanners} so that we’re not implicitly making API
 * calls from sub-components.
 *
 * Filters down to only return alerts that are currently within their time
 * window.
 */
export function useAlerts(
  services: Pick<Services, 'voterEducation'>,
  jurisdictionConfig: Option<JurisdictionConfig>,
  now = moment()
): Alerts {
  const location = useLocation();
  const urlHelper = UrlHelper.fromLocation(location);
  const contentfulParams = urlHelper.parseContentfulParams();

  // We load these via useQuery so that we don’t block first render on getting
  // them.
  const sitewideAlerts = useQuery(
    services.voterEducation.sitewideAlertsQueryOptions(
      !!contentfulParams.preview
    )
  );

  /* 
   If Contentful becomes unresponsive, we want to be able to spin up
   "hard-coded" alert banners independent from the Contentful service. 
   By using the BackupAlertBanner component, IWV can render a LocalizedMessage
   either pulling from already configured alert messages, found in 
   src/baseline/jurisdiction-info/alert-banners/alert-messages.ts,
   or by directly hard coding a message here.
  */
  const backupAlertMessage: BackupAlert = {
    startDate: moment('1900-01-01'),
    endDate: moment('1900-01-01'),
    alertMessage: {
      ar: '',
      en: '',
      es: '',
      ko: '',
      tl: '',
      vi: '',
      'zh-Hans': '',
    },
  };

  /* With Contentful managed alerts, hook holds the logic to find active
  alert(s). The RichTextAlertBanner itself is only responsible for displaying
  what it is given.
  */
  return {
    activeJurisdictionAlert:
      jurisdictionConfig?.jurisdictionAlert &&
      findActiveAlert(jurisdictionConfig.jurisdictionAlert, now),
    activeSitewideAlert:
      sitewideAlerts.data?.sitewideAlerts &&
      findActiveAlert(sitewideAlerts.data.sitewideAlerts, now),
    activeBackupAlertMessage: findActiveBackupAlert(backupAlertMessage, now),
  };
}
